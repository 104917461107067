.StorePageHeader {
    top: 70px;
    padding: 5rem 2rem 4.5rem;
    box-sizing: border-box;
    height: 450px;
}

.StorePageHeader .Content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    /*width: 75rem;*/
    margin: auto;
    position: relative;

}

.StorePageHeader .ContentLeft {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.StorePageHeader .ExitAndShopBrandGroup {
    display: flex;
    flex-direction: row;
    margin-top: auto;
}

.StoreImage {
    display: flex;
    flex-direction: column;
    max-width: 480px;
    height: 100%;
    /*position: absolute;*/
    right: 0;
    /*bottom: 2.1875rem;*/
    cursor: pointer;
}

.Image {
    position: absolute;
    right: 0;
    bottom: 0;
    height: 300px;
    width: auto;
    margin-right: 11rem;
}

.StoreInfoHeaderMobile {
    padding: 0.5rem;

}