.ImagesUploadHolder {
    display: flex;
}

.ImageUploader {
    margin: 1rem;
    max-height: 60vh;
    overflow-y: scroll;
    border: 1px solid #5b6977ff;
    border-radius: 6px;
}

.ButtonsHolder {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem 1rem;
}

.UploadProgressMessage {
    width: 900px;
    height: 600px;
    position: fixed;
    top: calc(50% - 300px);
    left: calc(50% - 450px);
    border: 1px solid grey;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Modal {
    width: 800px;
    left: calc(50% - 400px);
    z-index: 40;
}

@media screen and  (max-width: 699px) {
    .Modal {
        width: 95vw;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}