.UnknownItemMessageHolder {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
}

.UnknownItemMessage {
    padding: 0 30px;
    display: flex;
    justify-content: center;
}

.UnknownItemMessage .UnknownItemIcon {
    width: 24px;
    height: 24px;
    margin: auto 10px;
}

.AdditionalActionsHolder {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.SupportHolder {
    background-color: white;
    border-radius: 6px;
    box-sizing: border-box;
    width: 372px;
    height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #e2e8ecff;
    text-align: center;
}

@media screen and (min-width: 700px) {
    .UnknownItemMessageHolder {
        width: 50%;
        align-self: center;
    }
    .AdditionalActionsHolder {
        padding: 30px;
        justify-content: center;

    }
}