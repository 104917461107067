.AuthPageHolder {
    height: 100vh;
    width: 100vw;
    display: flex;
    background-color: white;
}

.AuthPageContainer {
    display: flex;
    z-index: 2;
    background-color: white;
    position: relative;
    width: 100%;
}

.AuthPageContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    box-sizing: border-box;
    padding: 1rem 10% 0;
    z-index: 2;
    background-color: white;
    width: 100%;
}

.AuthPageContent .Form {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 10px;
    width: 100%;
}

.AuthPageContent .Form input {
    width: 90%;
    margin-top: 0.85rem;
    border: 1px solid #e5e5e5;
    border-radius: 3px;
    padding: 2%;
    font-size: 14px
}

.ErrorMessage {
    color: red;
    font-size: 12px;
    width: 90%;
    margin-top: 0.2rem;
}

.AuthPageLogo {
    cursor: pointer;
    width: 250px;
    height: auto;
}

.GoogleButton {
    /*background-color: red !important;*/
    color: #4a4f54 !important;
    border-radius: 6px !important;
    box-shadow: none !important;
    font-family: 'Lexend', sans-serif !important;
    border: 1px solid #e2e8ecff !important;
    padding: 3px 6px !important;
    width: 100%;
    margin-top: 1.5rem;
}

@media screen and (min-width: 700px){
    .AuthPageLogo {
        /*width: auto;*/
    }
    .GoogleButton {
        width: auto;
    }

    .AuthPageContent {
        padding: 7rem 25% 0;
    }
}

.TopContainer {
    /*height: 250px;*/
    display: flex;
    flex-direction: column;
    padding: 5rem 1.8rem 1rem;
    align-items: center;
}

.HeaderContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.HeaderText {
    font-size: 30px;
    font-weight: 600;
    line-height: 1.24;
    color: #4a4f54ff;
    z-index: 10;
    margin: 0;
}

.SmallText {
    color: #4a4f54ff;
    font-weight: 500;
    font-size: 12px;
    z-index: 10;
    margin: 8px 0 0;
}

.Links {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 12px;
    justify-content: center;
}

.Links p {
    font-size: 14px;
    color: #535358;
}

.Links a {
    color: #416caa;
    cursor: pointer;
}

.Links a:hover {
    text-decoration: underline;
}

/*.ConfirmButton {*/
/*    background-color: #416caa;*/
/*    color: white;*/
/*    padding: 12px 30px;*/
/*    border-radius: 25px;*/
/*    font-weight: bold;*/
/*    border: none;*/
/*    cursor: pointer;*/
/*    margin-top: 10px;*/
/*}*/

/*.ConfirmButton:hover {*/
/*    background-color: #4a76af;*/
/*}*/

.Legal, .Legal:hover, .Legal:link, .Legal:visited, .Links:active {
    cursor: pointer;
    text-decoration: underline;
    color: #013a63ff;
}

.Checkbox {
    display: flex;
}

/*.Checkbox.Input {*/
/*    width: 2rem;*/
/*}*/

.AuthPageCommercial{
    display: flex;
    width: 55%;
    height: 100%;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    position: relative;
}

.Grad {
    background: -moz-linear-gradient(left,  rgba(255,255,255,1) 0%, rgba(255,255,255,0.75) 25%, rgba(255,255,255,0.5) 50%, rgba(255,255,255,0.25) 75%, rgba(255,255,255,0) 100%);
    background: -webkit-linear-gradient(left,  rgba(255,255,255,1) 0%,rgba(255,255,255,0.75) 25%,rgba(255,255,255,0.5) 50%,rgba(255,255,255,0.25) 75%,rgba(255,255,255,0) 100%);
    background: linear-gradient(to right,  rgba(255,255,255,1) 0%,rgba(255,255,255,0.75) 25%,rgba(255,255,255,0.5) 50%,rgba(255,255,255,0.25) 75%,rgba(255,255,255,0) 100%);

    /*background: -moz-linear-gradient(left,  rgba(255,255,255,0) 0%, rgba(255,255,255,0.25) 25%, rgba(255,255,255,0.5) 50%, rgba(255,255,255,0.75) 75%, rgba(255,255,255,1) 100%);*/
    /*background: -webkit-linear-gradient(left,  rgba(255,255,255,0) 0%,rgba(255,255,255,0.25) 25%,rgba(255,255,255,0.5) 50%,rgba(255,255,255,0.75) 75%,rgba(255,255,255,1) 100%);*/
    /*background: linear-gradient(to right,  rgba(255,255,255,0) 0%,rgba(255,255,255,0.25) 25%,rgba(255,255,255,0.5) 50%,rgba(255,255,255,0.75) 75%,rgba(255,255,255,1) 100%);*/

    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=1 );
    width: 200px;
    height: 100vh;
    position: absolute;
    left: 0;
    z-index: 1;
}

.ModalContentWrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}