.ShopInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;
}

.PharmacyName {
    font-size: 16px
}

.WorkingHours {
    display: flex;
    align-items: center;
    font-size: 16px;
}

.PharmacyClosed {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 20px;
    color: #00B2B1;
}

.PharmacyClosedText {
    text-align: center;
}

.PharmacyAddress {
    font-size: 14px;
    margin-bottom: 0.5rem;
}

@media screen and (min-width: 700px) {
    .ShopInfo {
        align-items: flex-start;
        margin-top: 0;
    }

    .PharmacyName {
        font-size: 24px;
        font-weight: 700;
    }

    .WorkingHours {
        font-size: 18px;
    }

    .PharmacyClosed {
        padding: 8px 0;
    }

    .PharmacyClosedText {
        text-align: left;
    }

    .PharmacyAddress {
        font-size: 16px;
    }
}