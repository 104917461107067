.ArticleCategory {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 32px;
    color: #000000;
    stroke: #00B2B1;
    padding: 0 1rem;
    box-sizing: border-box;
    /*border-bottom: 1px solid #00B2B1;*/
    cursor: pointer;
}

.ArticleCategoryName {
    font-size: 16px;
}

.ArticleCategorySelected {
    background-color: #00B2B1;
    color: white;
    stroke: white;
}