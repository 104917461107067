.StoreFilter {
    display: flex;
    flex-direction: column;
    width: 100vw;
    max-height: 600px;
    cursor: default;
}

@media screen and (min-width: 700px) {

    .StoreFilter {
        width: 270px;
    }

    .StoreFilter::after {
        /* the arrow */
        content: "";
        border: 10px solid rgb(74, 79, 84);
        border-color: transparent transparent white transparent;
        position: absolute;
        top: -22px;
        left: 50px;
    }
}

.StoreFilter .RootCategoryWrapper {
    flex-grow: 1;
    background-color: rgb(246, 248, 249);
    display: flex;
    flex-direction: column;
}

.StoreFilter .RootCategoryList {
    padding-top: 12px;

}

.StoreFilter .RootCategoryList p {
    margin: 4px 0 0 0;
    padding: 8px 16px;
    max-height: 36px;
    font-weight: normal;
    background-color: white;
}

.StoreFilter .SubCategoriesWrapper {
    /*flex-grow: 2;*/
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}

.StoreFilter .SubCategoryWrapper {
    /*width: 300px;*/
    margin-bottom: 8px;
    margin-top: 8px;
    text-align: left;
    padding-left: 24px;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 14px;
    font-weight: normal;
    box-sizing: border-box;
}

.StoreFilter .SubCategoryTitle {
    font-weight: bold;
    font-size: 16px;
    padding: 16px;
    display: flex;
    align-items: center;
}

.StoreFilter .SubCategoryList {
    display: flex;
    flex-direction: column;
    /*flex-flow: row wrap;*/
    font-weight: bold;
    height: 100%;
    width: 100%;
    align-content: flex-start;
    justify-content: center;
}

.StoreFilter .ActiveFilter {
    color: #00A4B4;
}

.StoreFilter .CitySearch {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-right: 8rem;
}

