.SearchedArticleItemMobile {
    /*box-shadow: 0 0 0.8rem #cccccc;*/
    /*box-shadow: rgb(239, 239, 239) 0.313rem 0.313rem 1.313rem 0.375rem;*/
    background-color: white;
    background-blend-mode: overlay;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /*align-items: center;*/
    margin-bottom: 30px;
    padding: 0.2rem 0.3rem 1rem 0.3rem;
    border: 1px solid #e2e8ecff;
    position: relative;
    border-radius: 6px;
    width: 43vw;
    margin-right: 0.75rem;
    margin-left: 0.75rem;
}

.SearchedArticleMobile {
    /*border: 2px solid #88959eff;*/  /* ff na kraju verovatno greskom dodato - treba #88959e */
    border: 2px solid #abb4bb;  /* postavljena malo svetlija nijansa, na zahtev Ferenca */
}

.SearchedArticleItemMobile:hover{
    /*border: 1px solid #adadad;*/
    box-shadow: 0 0 1.2rem #acaaaa;
    transition: 400ms ease-out;
}

/*.ArticleItem:hover {*/
/*    !*border: 1px solid #416CAA;*!*/
/*    !*border: 2px solid rgba(65, 108, 170, 0.5);*!*/
/*    background-color: #faf9f1;*/
/*    transition: 400ms ease-out;    */
/*}*/

.SearchedArticleItemMobile .Selected {
    background-color: #f5f5f6;
    box-shadow: 0 0 1.2rem #898787;
}

.SearchedArticleItemMobile .ItemPicture {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
}

.SearchedArticleItemMobile .ItemPicture img {
    max-height: 100%;
    max-width: 100%;
}

.SearchedArticleItemMobile .ArticleNameWrapper {
    height: 3.58rem;
    width: 100%;
    margin: 0 auto;
}


.SearchedArticleItemMobile .ArticleName {
    color: black;
    font-size: 14px;
    margin-bottom: 0.5rem;
    text-align: left;
    padding: 0 0.5rem 0;
    /*cursor: pointer;*/
}

.SearchedArticleItemMobile .ItemPrice {
    color: #121010;
    font-size: 14px;
    margin: 0;
}

.SearchedArticleItemMobile .RsdSpan {
    font-size: 12px;
    margin: 0
}

.SearchedArticleItemMobile .ArticleItemPriceRegulativa {
    font-size: 12px;
    color: #4D5055;
}

.SearchedArticleItemMobile .BottomLineWrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    bottom: 1rem;
    justify-content: center;
    height: 2rem;
    border-top: 1px solid lightgray;
    padding-top: 1rem;
}

.SearchedArticleItemMobile .MoreDetails {
    /*font-size: 12px;*/
    display: none;
}

.SearchedArticleItemMobile .StoreItemListView p {
    color: #535358;
    font-size: 1rem;
}

.SearchedArticleItemMobile .StoreItem .StoreItemGridView .DisplaySpaceBetweenGridView {
    display: flex;
    vertical-align: center;
}

.SearchedArticleItemMobile .AdditionalInfo {
    width: 100%;
    box-sizing: border-box;
    padding: 0 0.5rem;
    margin-top: auto;
}

.SearchedArticleItemMobile .AdditionalInfoListView {
    width: 15rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.SearchedArticleItemMobile .LocationAndAddress {
    width: 25rem;
}

.SearchedArticleItemMobile .ListViewPhoneNumber {
    width: 12rem;
}

.SearchedArticleItemMobile .AdditionalInfoGridView .Access,
.SearchedArticleItemMobile .AdditionalInfoListView .Access {
    color: #008D36;
}

.SearchedArticleItemMobile .AdditionalInfo .Denied {
    color: #E53132;
}

.SearchedArticleItemMobile .StoreItemListView i,
.SearchedArticleItemMobile .StoreItemGridView i{
    line-height: 0.4;
}

.SearchedArticleItemMobile .TooltipText {
    visibility: hidden;
    display: flex;
    justify-content: center;
    align-self: center;
    /*width: content-box;*/
    font-size: 0.875rem;
    font-weight: bold;
    background-color: rgba(65,108,170,0.8);
    color: white;
    border-radius: 15px 15px 0 15px;
    padding: 14px 14px;
    box-shadow: 0 0 0.8rem #cccccc;
    box-sizing: border-box;


    /* Position the tooltip */
    position: absolute;
    left: 0;
    top: -1.6rem;
    z-index: 999;
    opacity: 0;
    align-items: center;
}

.SearchedArticleItemMobile .StoreItemGridView:hover .TooltipText {
    visibility: visible;
    opacity: 1;
    transition: 600ms ease-out;
}

.SearchedArticleItemMobile .IconStyle {
    /*fill: white;*/
    height: 16px;
    width: 16px;
    stroke-width: 1;
    padding-right: 0.25rem;
    /*margin: auto;*/
}

.SearchedArticleItemMobile .ArticleItem .BottomHolder {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
}

.SearchedArticleItemMobile .ArticleInfoIcon:hover {
    cursor: pointer;
}

.SearchedArticleItemMobile .ArticleItem  .PriceButton {
    color: #416CAA;
    font-size: 1.125rem;
    min-height: 3.58rem;
    text-decoration: underline;
    cursor: pointer;
}

.SearchedArticleItemMobile .ItemAvailability {
    position: absolute;
    right: 0;
}


@media screen and (min-width: 700px) {
    .SearchedArticleItemMobile {
        width: 18.938rem;
        height: 408px;
        margin-right: 30px;
        margin-left: 0;
        padding: 1rem 1.3rem 1rem 1.3rem;
    }

    .SearchedArticleItemMobile .ItemPicture {
        height: 13rem;
        padding: 1rem;
    }

    .SearchedArticleItemMobile .ArticleName {
        font-size: 16px;
        font-weight: 700;
    }

    .SearchedArticleItemMobile .ItemPrice {
        font-size: 1.125rem;
    }

    .SearchedArticleItemMobile .ArticleItemPriceRegulativa {
        font-size: 16px;
    }

    .SearchedArticleItemMobile .BottomLineWrapper {
        justify-content: flex-start;
    }

    .SearchedArticleItemMobile .MoreDetails {
        cursor: pointer;
        font-size: 16px;
        color: #219EBC;
    }
    .SearchedArticleItemMobile .RsdSpan {
        font-size: 16px;
        margin: 0
    }
}

@media screen and (max-width: 699px){
    .SearchedArticleItemMobile .ItemAvailability {
        top: -10rem;
    }
}

.SearchedArticleItemMobile .ItemAvailabilityTooltipText {
    /* Position the tooltip */
    left: -11rem;
    top: 2.2rem;
}

.SearchedArticleItemMobile .ItemAvailabilityTooltipText::after {
    /* position tooltip correctly */
    left:80%;

    /* vertically center */
    bottom: 94%;
}

.SearchedArticleItemMobile .ItemAvailability:hover .ItemAvailabilityTooltipText,
.SearchedArticleItemMobile .ItemAvailability:hover .ItemAvailabilityTooltipText:after
{
    visibility: visible;
    opacity: 1;
    transition: 600ms ease-out;
}

.SearchedArticleItemMobile .LoyaltyPointsIcon {
    position: absolute;
    right: 10px;
    top: 10px;
}

.SearchedArticleItemMobile .PriceAndDetailsHolder {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 8px;
}

.SearchedArticleItemMobile .RpBadge {
    position: absolute;
    right: 0;
    top: 0;
    width: 0;
    height: 0;
    border-top: 60px solid transparent;
    border-bottom: 60px solid transparent;
    border-left: 60px solid #fc6d6dff;
}

.SearchedArticleItemMobile .QuantityInCart {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -11px;
    right: -11px;
    border-radius: 50%;
    background-color: #219EBC;
    color: white;
    width: 22px;
    height: 22px;
}

.SqBox {
    position: relative;
}

.TooltipSBText {
    position: absolute;
    visibility: hidden;
    right: -20px;
    /*left: -10.65rem;*/
    top: 38px;
}

.TooltipSBText::after {
    /* position tooltip correctly */
    right:10%;
    /* vertically center */
    bottom: 98%;
}

.SqBox:hover .TooltipSBText,
.SqBox:hover .TooltipSBText:after
{
    visibility: visible;
    opacity: 1;
    transition: 600ms ease-out;
}