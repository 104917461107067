/*.RightPane {*/
/*    width: 100%;*/
/*    height: 100%;*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    justify-content: space-between;*/
/*    border-radius: 0.375rem;*/
/*    box-sizing: border-box;*/
/*    padding: 0.5rem;*/
/*}*/

.ShopInfo {
    color: #5b6977ff;
}

.UserInfo {
    background-color: #F09200;
    color: white;
    border: 1px solid #F09200;
    border-radius: 15px;
    padding: 1rem;
}

.CartSummaryContinueShoppingButtons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.CartSummaryContinueShoppingButtons .IconWrapper {
    /*border: 1px solid #5b6977ff;*/
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.8rem;
    height: 1.8rem;
    border-radius: 50%;
    padding:.3rem;
}

.LeftPartHeader {
    height: 110px;
    border-bottom:1px solid #E2E8EC;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.CartActionsWrapper {
    display: flex;
    justify-content: space-between;
    padding-bottom: 1rem;
}