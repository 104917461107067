.Order {
    padding: 1rem 1.5rem;
    border-radius: 15px;
    width: 96%;
    height: max-content;
    box-shadow: 0 0 0.8rem #cccccc;
    display: flex;
    flex-direction: row;
}

.OrderInfoPart {
    width: 70%;
}

.OrdersPane {

}

.OrdersPane h2 {
    font-weight: 500;
}

.FeedbackPart {
    display: flex;
    align-items: center;
    justify-content: center;
}

.BlinkRed{
    width:200px;
    height: 20px;
    background-color: red;
    padding: 15px;
    text-align: center;
    line-height: 20px;
    border-radius: 25px;
}
.BlinkRed span{
    font-size: 14px;
    color: white;
    animation: blink .75s alternate infinite;
}

.BlinkGreen{
    width:200px;
    height: 20px;
    background-color: greenyellow;
    padding: 15px;
    text-align: center;
    line-height: 20px;
    border-radius: 25px;
}
.BlinkGreen span{
    font-size: 14px;
    color: white;
    animation: blink .75s alternate infinite;
}


@keyframes blink{
    0%{opacity: 0;}
    50%{opacity: .5;}
    100%{opacity: 1;}
}

@media screen and (max-width: 699px){
    .OrdersPane h2 {
        display: none;
    }
}
