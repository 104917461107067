.InactiveToolbarWrapper {
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
}

.InactiveToolbarContent {
    width: 1366px;
    float: left;
    height: 3.5rem;
    padding:0.5rem 1rem;
    box-sizing: border-box;
}