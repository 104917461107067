.MainPage {
    display: flex;
    flex-direction: column;
}

.BackToTop {
    bottom: 6.25rem;
    margin: 0 50%;
    position: sticky;
    z-index: 999;
}

.BackToTop button {
    border-radius: 3.125rem;
    border: none;
    background-color: #416CAA;
    color: white;
    width: 2.5rem;
    height: 2.5rem;
    font-size: 1.5rem;
}

.BackToTop button:hover {
    background-color: rgb(94, 140, 219);
}

.SectionThreeWrapper {
    display: flex;
    flex-direction: row;
}

.SectionThreeImageWrapper {
    margin-top: 2rem;
    border-radius: 0 200px 100px 0;
    background-image: url("../../assets/crop_nurse-2141808_960_720.jpg");
    background-repeat: no-repeat;
    background-size: 100%;
    height: 100vh;
    width: 40vw;
    z-index: 10;
}

.SectionThreeTextWrapper {
    height: 100vh;
    width: 50%;
    padding-left: 15%;
    box-sizing: border-box;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #535358;
}

.SectionAboutUs {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 64px;
    position: relative;
    height: 100%;
    box-sizing: border-box;
}