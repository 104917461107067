.ResultsContainer {
    width: 100vw;
    /*background-color: #f3f6f8ff;*/
}
.ArticleItemsWrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0;
    width: 100vw;
}

@media screen and (min-width: 700px) {

    .ResultsContainer {
        width: 100%;
    }

    .ArticleItemsWrapper {
        width: 100%;
        margin-left: 2rem;
        margin-right: 0;
        justify-content: flex-start;
    }

    .ShopSelectionMenu {
        height: 2rem;
    }
}

.ListOrGridToggleButtons {
    width: 100%;
    height: 3rem;
    /*border: 1px solid black;*/
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 1.5rem;
}

.ListOrGridToggleButton {
    width: 2.5rem;
    height: 2.5rem;
    /*border: 1px solid beige;*/
    border-radius: 7px;
    box-shadow: 3px 3px 0.4rem #585757;
    margin-right: 0.5rem;
    color: #535358;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-style: none;
}

.ListOrGridToggleButton:hover {
    cursor: pointer;
}

.ToggleButtonSelected {
    background-color: #f5f5f6;
    border: 2px solid transparent;
    box-shadow: 0 0 0.4rem #585757;
}


.ListView {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
}

.ReklamaListView {
    width: 71rem;
    background-color: lightgreen;
    height: 4rem;
    display: flex;
    align-items: center;
    margin-bottom: 0.8rem;
    padding: 0 2rem;
    border-radius: 15px;
    box-shadow: 0 0 0.8rem #cccccc;


}

.ListViewSoftEnter,
.GridViewSoftEnter {
    animation: soft-enter 300ms ease-in;
}

@keyframes soft-enter {
    0% { opacity: 0}
    100% { opacity: 1}
}

.ShopSelectionMenu {
    height: 1rem;
    width: 75rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.ShopArticleSelect {
    min-width: 10rem;
    /*width: max-content;*/
    padding: 0.3rem;
    background-color: white;
    border-radius: 10px;
    height: 2.1rem;
    font-size: 0.875rem;
}


/*grid/list toggle buttons*/
.ListOrGridToggleButtons {
    width: 6rem;
    height: 1rem;
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    /*padding-left: 1.5rem;*/
}

.ListOrGridToggleButton {
    width: 2rem !important;
    height: 2rem !important;
    /*border: 1px solid beige;*/
    border-radius: 7px;
    box-shadow: 3px 3px 0.4rem #585757;
    margin-right: 0.5rem;
    color: #535358;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-style: none;
}

.ListOrGridToggleButton:hover {
    cursor: pointer;
    opacity: 0.6;
}

.ToggleButtonSelected {
    background-color: #f5f5f6;
    border: 2px solid transparent;
    box-shadow: 0 0 0.2rem #585757;
}

.SearchResultErrorMessage{
    margin-right: 64px;
    padding: 0 32px;
    display: flex;
    background-color: #f5f5f6;
    font-size: x-large;
    color: #535358;
}