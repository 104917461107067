
.Pages {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 1rem;
}

.Pages button {
    /*!*background-color: transparent;*!*/
    /*!*border-color: transparent;*!*/
    /*color: #416CAA;*/
    font-size: 1rem;
    /*width: 3rem;*/
    /*==============================================*/
    color: #88959E;
    width: 2.5rem;
}

.Pages button:hover {     /*  ovde izmeniti nesto, ako treba (mozda samo boju!?) */
    /*cursor: pointer;*/
    /*background-color: #faf9f1;*/
    /*transition: 400ms ease-out;*/
}

.Pages .PaginationButton:hover {
    cursor: pointer;
    background-color: #faf9f1;
    transition: 400ms ease-out;
}

.Pages p {
    /*color: #416CAA;*/
    font-size: 1.125rem;
    /*==============================================*/
    color: #88959E;
}

.Pages .Arrow button {     /*  ovde izmeniti nesto, ako treba (mozda samo boju!?) */
    color: #4285F4;
    font-size: 1.563rem;
}

.PaginationButton {
    background-color: white;
    /*border: 1px solid lightgray;*/
    /*border-radius: 7px;*/
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
    /*box-shadow: 0 0 0.8rem #cccccc;*/
    margin-right: 0.3rem;
    margin-left: 0.3rem;
    /*==============================================*/
    border: 2px solid #E2E8EC;
    border-radius: 6px;
    /*box-shadow: 0 0 0.8rem #cccccc;*/


}

.PaginationButtonPressed {
    /*background-color: #f5f5f6;*/
    /*box-shadow: 0 0 0;*/
    /*==============================================*/
    background-color: #E2E8EC;
    /*box-shadow: 0 0 0;*/
}

.Arrow {                   /*  ovde izmeniti nesto, ako treba (mozda samo boju!?) */
    color: #4285F4;
}


.PaginationArrow {
    background: transparent;
    border: none;
}

.PaginationArrow:hover {
    cursor: pointer;
    transition: 400ms ease-out;
}

.ArrowIcon {
    height: 16px;
    width: 16px;
}
