.BigButton {
    border: 1px solid #e2e8ecff;
    border-radius: 6px;
    padding: 0;
    box-sizing: border-box;
    cursor: pointer;
    width: 250px;
    height: 4rem;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: transparent;
}

.Link {
    background-color: transparent;
    color: #5b6977ff;
    text-decoration: underline;
    font-weight: bold;
    border: none;
    cursor: pointer;
    padding: 1rem;
}