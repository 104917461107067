.Billboard {
    /*min-height: 400px;*/
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-top: 4vh;
    width: 75rem; /*1200px*/
    margin: auto;
}
