.ResetPasswordPane {
    padding-top: 100px;
    height: calc(100% - 5.25rem);
    width: 100%;
    display: flex;
    justify-content: center;
}

.ResetPasswordPaneHolder {
    width: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Input {
    padding: 10px 0;
    width: 100%;
}

.ResetPasswordButtonHolder {
    display: flex;
    justify-content: center;
}


.ResetPasswordButton:hover {
    background-color: #4a76af;
}

@media screen and (max-width: 699px) {
    .ResetPasswordPaneHolder {
        width: 100%;
        margin: 0 auto;
    }
    .Input {
        width: 90%;
    }
    form {
        width: 100%;
    }
}