.TherapyArticle {
    border-bottom: 1px solid #E2E8EC;
    padding: 24px 0;
    margin-left: 4%;
    font-size: 14px;
    font-weight: bold;
    display: flex;
}

.Information {
    display: flex;
    padding-top: 1rem;
}

.ArticleLabels {
    display: flex;
    flex-direction: column;
    border-right: 1px solid #E2E8EC;
    padding-right: 2rem;
    font-weight: bold;
    font-size: 12px;
}

.ArticleLabel {
    padding-bottom: 1rem;
}

.ArticleDetails {
    display: flex;
    flex-direction: column;
    padding-left: 2rem;
    font-size: 12px;
    font-weight: normal;
    padding-bottom: 1rem;
}

.ArticleNameAndQtyWrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.IconStyle {
    stroke: #8B8C8F;
    height: 18px;
    width: 18px;
    cursor: pointer;
}

.IconStyle:hover {
    stroke: #00B2B1;
}

.WrapperForTherapyImage {
    width: 100px;
}

.ImageForTherapy {
    width: inherit;
    padding-right: 6px;
    box-sizing: border-box;
}