.ArticleInfoPageWrapper {
    width: 75rem;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
}

.ArticleInfoName {
    width: 100%;
    color: #416CAA;
    display: flex;
    justify-content: flex-start;
    font-size: 1.5rem;
}

.BasicInfoContainer {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    box-shadow: 0 0 0.8rem #cccccc;
    margin-top: 1.5rem;
}

.BasicInfoLeftSide {
    /*width: 24.5%;*/
    width: 27%;
    border: 6px solid white;
    border-radius: 6px 0 0 6px;
    background: white;
    margin-right: 2px;
    padding: 0 2rem;
}

.BasicInfoRightSide {
    /*width: 74.5%;*/
    width: 73%;
    border: 4px solid white;
    border-radius: 0 6px 6px 0;
    background: white;
    margin-left: 2px;
    padding: 0 2rem;
    color: #4a4f54;
}

.ParagraphTitleWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #E2E8EC;
    padding: 0.5rem 0;
    cursor: pointer;
}

.ParagraphTitleWrapper:hover {
    color: #00B2B1 !important;
}

@media screen and (min-width: 700px){

    .BasicInfoContainer {
        width: auto;
        margin: 0 32px;
        background: #F3F6F8;
        height: 465px;
    }

    .ParagraphTitleWrapper {
        padding: 0;
    }
}

.ParagraphTitle {
    padding: 0.5rem 0.5rem;
    /*font-size: 1.1rem;*/
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.ParagraphTitleActive {
    color: #00B2B1;
    padding: 0.5rem 0.5rem;
    /*font-size: 1.1rem;*/
    /*font-weight: bold;*/
    display: flex;
    justify-content: flex-start;
    align-items: center;
}



