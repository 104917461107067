.SearchItems{
    width: 100%;
    background-color: white;
    /*border-radius: 2.188rem;*/
    display: flex;
    flex-direction: column;
    padding-left: .5rem;
    position: absolute;
    z-index: 21;
    box-shadow: 0 0 0.625rem -0.25rem #535358;
    max-height: 50vh;
    top: 2.5rem;
    box-sizing: border-box;
    border-radius: 0 0 3px 3px;
}

@media screen and (min-width: 700px) {
    .SearchItemsMainPage {
        width: 100%;
        padding: 1.6rem 0 0.125rem 0;
        top: 2rem;
    }

    .SearchItemsActionMenu {
        width: 100%;
        top: 2rem;
    }

    .SearchItems{
        padding: 1.6rem 0 0.125rem 0;
        max-height: 39vh;
        top: 2rem;
    }
}

.SearchItems .Bottom{
    float: bottom;
    overflow-y: scroll;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    /*  deo (ispod) dodat zbog horizontalnog scroll-a za duge nazive artikala  */
    overflow-x: hidden;
    padding-inline-end: 2rem;
}

.SearchItems .Bottom::-webkit-scrollbar-thumb{
    background-color: #dfdfdf;
    border: 0.25rem solid transparent;
    border-radius: 0.5rem;
    background-clip: padding-box;
}

.SearchItems .Bottom::-webkit-scrollbar{
    width: 0.938rem;
}
