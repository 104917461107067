.ArticleActionsHolder {
    /*width: 50%;*/
    width: 23%;
    height: 100%;
    box-sizing: border-box;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: white;
    /*border: 6px solid #ffffff;*/
    border-radius: 0 6px 6px 0;
    color: #88959E;
}