.InformationBox {
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    background-color: white;
    border: 1px solid #E2E8EC;
    box-shadow: 0 0 0.8rem #E2E8EC;
    padding: 27px;
}

.InfoTitle {
    color: #434E59;
    margin-bottom: 27px;
}

.LeftBoxItemWrapper {
    display: flex;
    flex-direction: row;
    margin-bottom: 27px;
}

.IconStyle {
    height: 24px;
    background-color: #88959E;
    color: white;
    /*width: 81px;*/
    width: 24px;
    margin-right: 27px;
    border-radius: 3px;
    padding: 0.1rem;
    box-sizing: border-box;
    }

.PharmacyInfo {
    color: #434E59;
}

.PharmacyAddress {
    cursor: pointer;
    color: #219EBC;
}

.PharmacyPhone {
    color: #219EBC;
}

.PharmacyEmail {
    color: #219EBC;
}

.PharmacyOpeningHoursWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
}
.PharmacyOpeningHoursDays {
    display: flex;
    flex-direction: row;
    color: #434E59;
}
.Day {
    width: 40%;
}

.CurrentDay {
    color: #219EBC;
}