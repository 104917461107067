.AddressesHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 1rem 0;
}

.AddressesHeader h2 {
    font-weight: 500;
}

.AddressesHolder {
    margin-top: 1rem;
}

@media screen and (min-width: 700px){
    .AddressesHeader {
        display: flex;
        justify-content: space-between;
    }
}