.ArticleAction {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.TooltipWrapper {
    position: relative;
}

.TooltipText {
    /* Position the tooltip */
    left: 3rem;
    top: 2.7rem;
}

.TooltipText::after {
    /* position tooltip correctly */
    left:10%;
    /* vertically center */
    bottom: 94%;
}

.TooltipWrapper:hover .TooltipText,
.TooltipWrapper:hover .TooltipText:after
{
    visibility: visible;
    opacity: 1;
    transition: 600ms ease-out;
}