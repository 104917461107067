.StoreItems {
    width: 85.375rem;
    /*background-color: #e0e2e4;*/
    /*display: inline-flex;*/
    /*justify-content: space-between;*/
    /*flex-direction: row;*/
    /*flex-wrap: wrap;*/
    /*left: 0.188rem;*/
    /*padding: 1rem 0px 0.625rem 0px;*/
}

.ElementInGridCell {
    display: flex;
    justify-content: center;
}

.Grid {
    width: 100vw;
    /*margin-left: -1.7rem;*/
    box-sizing: border-box;
    padding: 0;
    margin: 0.5rem auto 2rem;
}

@media screen and (min-width: 700px) {
    .ElementInGridCell {
        z-index: 10;
    }

    .Grid {
        width: 1340px;
    }
}

.ListView {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 1rem;
    padding: 1rem 0;
}

.ReklamaListView {
    width: 66rem;
    background-color: lightgreen;
    height: 4rem;
    display: flex;
    align-items: center;
    /*margin-bottom: 0.8rem;*/
    padding: 0 2rem;
    /*border-radius: 15px;*/
    box-shadow: 0 0 0.8rem #cccccc;


}

.ListViewSoftEnter,
.GridViewSoftEnter {
    animation: soft-enter 300ms ease-in;
}

@keyframes soft-enter {
    0% { opacity: 0}
    100% { opacity: 1}
}


.PagesHolder {
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}
