.BlueButton {
    background-color: #00B2B1;
    color: white;
    border-radius: 6px;
    font-weight: bold;
    border: none;
    cursor: pointer;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /*display: inline-block;*/
    text-align: center;
}

.BlueButton:hover {
    background-color: #16CBCA;
}

.LightBlueButton {
    background-color: #219ebcff;
    color: white;
    border-radius: 6px;
    font-weight: bold;
    border: none;
    cursor: pointer;
    padding: 1rem;
}

.LightBlueButton:hover,
.LightBlueButton:focus {
    background-color: #1c8ba4;
}

.WhiteBlueButton {
    background-color: white ;
    color: #00B2B1;
    border-radius: 6px;
    border: 1px solid #00B2B1;
    cursor: pointer;
    padding: 0.5rem 1rem;
}

.WhiteBlueButton:hover,
.WhiteBlueButton:focus {
    color: #103A5E;
    border: 1px solid #103A5E;
}

.YellowButton {
    background-color: #FFAE00 ;
    color: white;
    border-radius: 6px;
    border: 1px solid #FFAE00;
    cursor: pointer;
    padding: 0.5rem 1rem;
}

.YellowButton:hover,
.YellowButton:focus {
    background-color: #ECA002FF;
    border: 1px solid #ECA002FF;
}

.WhiteDarkBlueButton {
    background-color: white ;
    color: #103A5E;
    border-radius: 3px;
    border: 1px solid #103A5E;
    cursor: pointer;
    padding: 0 2rem;
    height: 37px;
    stroke: #103A5E;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.WhiteDarkBlueButton:hover,
.WhiteDarkBlueButton:focus {
    color: #219ebcff;
    border: 1px solid #219ebcff;
    stroke: #219ebcff;
    fill: #219EBC;
}

.WhiteBrownButton {
    background-color: white ;
    color: #9a877aff;
    border-radius: 6px;
    border: 1px solid #9a877aff;
    cursor: pointer;
    padding: 0 2rem;
    height: 37px;
}

.WhiteBrownButton:hover,
.WhiteBrownButton:focus {
    color: #103A5E;
    border: 1px solid #103A5E;
}

.Link {
    background-color: transparent;
    color: #5b6977ff;
    text-decoration: underline;
    font-weight: bold;
    border: none;
    cursor: pointer;
    padding: 1rem;
    display: flex;
    align-items: center;
}

.DisabledButton {
    background-color: #888888;
    color: white;
    border-radius: 6px;
    font-weight: bold;
    border: none;
    cursor: auto;
    padding: 1rem;
    /*border: 1px solid #416caa;*/
}