.ConfirmationModal {
    box-sizing: border-box;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    width: 440px;
    background-color: white;
    z-index: 35;
    position: fixed;
    left: 5vw;
    top: 15%;
    box-shadow: 0 0 1.2rem #acaaaa;
    max-width: 90vw;
}

@media screen and (min-width: 700px){
    .ConfirmationModal {
        top: 200px;
        left: calc(50% - 220px);
    }
}

.ConfirmationModal .Header {
    width: 100%;
    height: 60px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 10px;
    box-sizing: border-box;
    background-color: #00B2B1;
}

.Content {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding: 2rem;
    height: calc(100% - 60px);
    /*margin: auto;*/
    justify-content: flex-end;
    color: #5b6977ff;
}

.ActionWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 1rem;
    width: 45%;
}

@media screen and (min-width: 700px) {
    .ActionWrapper {
        width: 35%;
    }
}