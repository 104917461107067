.UserMenu {
    position: absolute;
    top: 2.5rem;
    right: 21%;
    width: 7rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 1px solid #888888;
    background-color: white;
    padding: 0.8em;
    font-size: 1rem;
    border-radius: 10px;
    z-index: 25;

}

.UserMenuOption {
    cursor: pointer;
    width: 100%;
    display: flex;
    flex-direction: column;
}
.UserMenuOption a {
    color: #535358;
}

.UserMenuOption a:visited {
    color: #535358;
}

.UserMenuOption a:hover {
    color: #416CAA;
}

.UserMenuLink {
    color: #535358;
    display: flex;
    justify-content: center;
    margin: auto;
    text-align: center;
}

.UserMenuLink:hover {
    color: #416CAA;
}

.UserMenuLinkDisabled {
    color: grey;
    cursor: auto;
    display: flex;
    justify-content: center;
    margin: auto;
    text-align: center;
}