.MyTherapiesWrapper {
    width: 100%;
    /*height: 100%;*/
    display: flex;
    flex-direction: column;
}

.TherapyHeaderLeftHalf {
    width: 90vw;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    margin-bottom: 32px;
    font-size: 18px;
}

.TherapyHeaderRightSide {
    width: 46%;
    margin-left: 4%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 32px;
    border-bottom: 1px solid #E2E8EC;
    font-size: 18px;
}

.TherapyHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
}

.MyTherapiesWrapperScroll {
    overflow-y: auto;
    height: 55vh;
    width: 50%;
}

.SingleTherapyWrapper {
    width: 90vw;
}

@media screen and (min-width: 700px){
    .TherapyHeaderLeftHalf {
        width: 50%;
    }
}

.NewTherapyMobile {
    position: fixed;
    bottom: 0;
    left: 0;
    /*width: 100vw;*/
    background: white;
    /*z-index: 2; */
    height: calc(100vh - 44px);
}