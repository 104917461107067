.LoyaltyPageWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 2rem;
}

.LoyaltyPageWrapper .LeftPart {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    width: 100%;
}

.LoyaltyPageWrapper h2 {
    font-weight: 500;
}

.LoyaltyPageWrapper .LoyaltyPointsWrapper {
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    border: 1px solid #e1e5e9ff;
    align-items: center;
    justify-content: space-between;
    padding: 8px 16px;
    width: 100%;
    font-size: 18px;
    box-sizing: border-box;
    margin-top: 1rem;
}

.LoyaltyPointsWrapper .Mark {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    color: #003b63ff;
}

.LoyaltyPointsWrapper .Points {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: #FFAE00;
    color: white;
    padding: 2px 36px;
    margin-bottom: 32px;
    margin-top: 8px;
}

.Points .Number {
    font-size: 22px;
    line-height: 48px;
    font-weight: bold;
}

.ProgressWrap {
    display: flex;
    position: relative;
    margin-top: 30px;
}

.LevelWrapper {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin: 8px;
}

.LevelLabelWrapper {
    display: flex;
    justify-content: space-between;
    width: 330px;
    margin-top: 15px;
}

.LevelLabelText {
    font-weight: bold;
    color: #8B8C8F;
}

.TimeNeededText {
    font-size: 14px;
}

.TimeNeededTime {
    color: #FFAE00;
}

@media screen and (min-width: 700px) {
    .LoyaltyPageWrapper {
        flex-direction: row;
    }

    .LoyaltyPageWrapper .LeftPart {
        width: 40%;
    }

    .LoyaltyPageWrapper .LoyaltyPointsWrapper {
        width: 55%;
    }

    .LoyaltyPointsWrapper .Points {
        padding: 10px 52px;
    }

    .Points .Number {
        font-size: 36px;
        line-height: 48px;
        font-weight: bold;
    }

    .LevelLabelWrapper {
        width: 516px;
        margin-top: 30px;
    }
}