.SearchDiv {
    height: auto;
    width: 100%;
    position: relative;
    border-radius: 3px;
    border: 1px solid #e6e6e6;
    padding-left: 2rem;
    outline: 0;
    box-shadow: 0 0 0 0 hsl(0deg 0% 53% / 0%);
    font-size: 1rem;
    color: #535358;
    z-index: 22;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
}

.IconStyle {
    width: 24px;
    height: 24px;
    stroke: #88959eff;
}

.IconStyleClicked {
    width: 24px;
    height: 24px;
    stroke: #00B2B1;
}

.IconStyle:hover {
    stroke: #00B2B1;
}