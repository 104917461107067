.SearchResults {
    /*background-color: #f3f6f8ff;*/
    display: flex;
    justify-content: center;
    /*margin: 0 auto;*/
    z-index: 0;
}

.SearchResultsRes {
    background-color: #f3f6f8ff;
    display: flex;
    justify-content: center;
}

.SearchResultsWrapper {
    width:  85.375rem;
    box-sizing: border-box;
    padding: 0;
    /*z-index: 0;*/
}

.SearchResultsWrapperRes {
    /*margin-top: 13rem;*/
    width: 100vw;
    box-sizing: border-box;
    padding: 0;
}

/*.LeftPane {*/
/*    width: 75rem;!* TODO 30 mapa*!*/
/*    display: flex;*/
/*    float: left;*/
/*    !*flex-direction: column;*!*/
/*    align-items: flex-start;*/
/*    justify-content: flex-start;*/
/*    !*margin-left: 18%;*!*/
/*    !*margin-top: 100px;*!*/
/*    !*padding-left: 12%; !* TODO 3 mapa*!*!*/
/*    margin-right: 2%;*/
/*    z-index: 10;*/

/*    transition: padding-left ease-out 1000ms, width 1000ms ease-out;*/
/*    padding-left: calc(1rem + 150px);*/
/*}*/

/*.LeftPaneOpen {*/
/*    !*padding-left: 3%;*!*/
/*    width: 30%;*/
/*    transform: scale(1);*/
/*}*/

/*.LeftPaneClosed {*/
/*    !*padding-left: 12%;*!*/
/*    width: 50%;*/
/*    transform: scale(1);*/
/*}*/

/*@keyframes moveLeftPane {*/
/*    0% {*/
/*        opacity: 1;*/
/*        transform: translateX(100%);*/
/*    }*/
/*    !*50% {*!*/
/*    !*    opacity: 1;*!*/
/*    !*    transform: translateY(90%);*!*/
/*    !*}*!*/
/*    100% {*/
/*        opacity: 1;*/
/*        transform: translateX(0);*/
/*    }*/
/*}*/

/*@keyframes returnLeftPane {*/
/*    0% {*/
/*        opacity: 1;*/
/*        transform: translateY(0);*/
/*    }*/
/*    50% {*/
/*        opacity: 0.8;*/
/*        transform: translateY(60%);*/
/*    }*/
/*    100% {*/
/*        opacity: 0;*/
/*        transform: translateY(-100%);*/
/*    }*/
/*}*/

/*.LeftPaneDataHolder {*/
/*    width: 100%;*/
/*    background: #eaeff8;*/
/*    !*margin-left: 20%;*!*/
/*}*/
.LeftPane::-webkit-scrollbar{
    display: none;
}


