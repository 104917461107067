.NavigationItem {
    font-size: 16px;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    align-items: center;
}

.NavigationItem li {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.NavigationItem a {
    color: #535358; /* bilo je white */
    text-decoration: none;
    height: 100%;
    padding: 0 0.25rem;
    box-sizing: border-box;
    display: block;

}

.NavigationItemDark a {
    color: #535358;
    text-decoration: none;
    height: 100%;
    padding: 0 0.625rem;
    box-sizing: border-box;
    display: block;

}

.Disabled {
    text-decoration: none;
    cursor: default;
    color: #8b8989 !important;
}

/*.NavigationItem a:active,*/
/*.NavigationItem a:hover,*/
/*.NavigationItem a.active {*/
/*    border-bottom: 4px solid #61dafb;*/
/*    color: white;*/
/*}*/

.NavigationItem .ItemsInCartCounter,
.NavigationItemDark .ItemsInCartCounter {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    /*padding: 6px;*/
    background-color: #00B2B1;
    color: white;
    font-size: 16px;
    font-weight: bold;
    border-radius: 3px;
}