.Toolbar {
    width: 100vw;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    z-index: 30;
    left: 0;
    background-color: white;
}

.ToolbarHolder {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100vw;
    box-sizing: border-box;
}

@media only screen and (min-width: 700px) {
    .Toolbar {
        width: 100%;
        height: 70px;
        /*flex-direction: row;*/
        justify-content: space-between;
        align-items: center;
    }

    .ToolbarHolder {
        width: 1366px;
        padding: 0.5rem 2rem 0.5rem;
    }

    .PositionAbsolute {
        position: absolute;
        /*background: linear-gradient(.50turn, white 18%, transparent 100%);*/
    }
}



.PositionFixed {
    position: fixed;
    border-bottom: 1px solid #e2e8ecff;
}

.ToolbarWhite {
    background-color: white;
    /*box-shadow: 0 0 0.8rem #cccccc;*/
}

.ToolbarBlue {
    background-color: #416CAA;
}

.ToolbarIcon {
    padding-right: 1.25rem;
}

.Toolbar img {
    /*max-width: 8.125rem;*/
}

.Toolbar .SearchBar {
    display: flex;
    align-content: flex-start;
    /*width: 50%;*/
    padding-bottom: 1.25rem;
    /*margin: auto 0 auto 0.625rem;*/
}

.Toolbar .SearchBarWrapper {
    width: 100%;
    display: flex;
    max-height: 2.5rem;
}

.SearchBarHidden {
    display: none;
}

.ToolbarRightContent {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
}

.SearchBarOnToolbar {
    padding: 0 1rem;
}
