.SearchResultsAM {
    display: flex;
    flex-direction: row;
    margin: auto 0;
    align-items: center;
}

.SearchResultsAM .IconStyle {
    width: 24px;
    height: 24px;
    stroke: #88959eff;
}

.SearchResultsAM .IconStyleClicked {
    width: 24px;
    height: 24px;
    stroke: #00B2B1;
}

.SearchResultsAM .IconStyle:hover {
    stroke: #00B2B1;
}

.FilterButton {
    color: #88959eff;
    background-color: white;
    width: 114px;
    height: 44px;
    font-size: 14px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-radius: 3px;
}

.FilterButton:hover {
    border: 1px solid #013a63ff;
}

.FilterButtonUnselected {
    border: 1px solid #e2e8ecff;
}

.FilterButtonSelected {
    border: 1px solid #00B2B1;
    color: #00B2B1;
}

.QuickFilter:hover {
    position: relative;
}

.TooltipText {
    /* Position the tooltip */
    left: -171px;
    top: 44px;
}

.TooltipText::after {
    /* position tooltip correctly */
    left:79%;
    /* vertically center */
    bottom: 94%;
}

.QuickFilter:hover .TooltipText,
.QuickFilter:hover .TooltipText:after
{
    visibility: visible;
    opacity: 1;
    transition: 600ms ease-out;
}

.FilterIconMobile{
    height: 22px;
    width: 22px;
    margin: auto 1rem;
    cursor: pointer;
}