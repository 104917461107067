.Content {
    padding-top: 72px;
}

.SectionAboutUs {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 64px;
    position: relative;
    height: 100%;
    box-sizing: border-box;
    color: #474A53;
}

.AllInOnePlace {
    color: #474A53;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    margin-left: 64px;
}

.ColoredBoxWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}