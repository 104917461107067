.MainPageCountersWrapper {
    /*width: 50%;*/
    /*margin-bottom: 5rem;*/
    box-sizing: border-box;
    background-color: rgba(255, 255, 255, 0.5);
    display: flex;
    flex-direction: row;
    padding-left: 0.5rem;
    box-shadow: 2px 2px 6px rgb(0 0 0 / 10%);
    border-radius: 9px;
}

.MainPageCountersWrapper .CounterText {
    margin: 0;
    font-weight: 600;
    font-size: 18px;
}

h4,
.Numbers {
    width: 100%;
    text-align: left;
    font-weight: 600;
}

.Numbers {
    font-size: 18px;
    color: #4D5055;
}

@media screen and (max-width: 699px){

    .MainPageCountersWrapper {
        display: flex;
        justify-content: space-between;
        width: inherit;
        margin: 0 1rem;
        box-sizing: border-box;
        padding: 0 1rem;
    }

    h4{
        font-size: 16px;
        font-weight: 600;
        margin: 0;
    }

    .Numbers {
        font-weight: 600;
        font-size: 16px;
    }
}