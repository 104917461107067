.GoodbyePageWrapper {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    /*background-color: #F9F9F9;*/
}

.MessageBox {
    width: 40%;
    height: min-content;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1.5rem;
    box-shadow: 0 0 0.8rem #cccccc;
}
.GoodbyePageWrapper a {
    color: white;
    text-decoration: none;
}


@media screen and (max-width: 699px) {
    .MessageBox p, .MessageBox a {
        text-align: center;
    }
}
