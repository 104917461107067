.PrescriptionImagePreviewHolder {
    width: 100px;
    border: 1px solid #8b8989;
    border-radius: 3px;
    box-sizing: border-box;
    padding: 5px;
    margin-bottom: 16px;
    margin-right: 6px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    cursor:pointer;
}

.PrescriptionImagePreview {
    max-width: 100px;
    max-height: 100px;
}

.PrescriptionImagePreview img {
    max-width: 100%;
    max-height: 100%;
}

.PrescriptionImageDeleteButton {
    position: absolute;
    right: 2px;
    top: 2px;
    width: 24px;
    height: 24px;
    border-radius: 3px;
    background-color: #ef7f1a;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    cursor: pointer;
    

}