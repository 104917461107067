.RFZOTherapyModalOnToolbar {
    height:auto;
    top:10%;
}

.RFZOTherapyModalContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.RFZOTherapyModalTitle {
    margin: 8px;
    padding-bottom: 8px;
    width: 95%;
    border-bottom: 1px solid #E2E6EA;
}

@media screen and (max-width:699px) {
    .RFZOTherapyModalOnToolbar {
        /*height: 98vh !important;*/
        top: 10px;
    }
    .RFZOTherapyModalContainer {
        overflow-y: auto;
    }
}
