.DeliveryOptionsHolderMobile {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.DeliveryOptionsHolderMobile .MessageHeader {
    font-size: 18px;
    text-align: left;
    margin-top: 1rem;
    margin-left: 1rem;
}

.ChoiceWrapper {
    display: flex;
    flex-direction: column;
    margin-top: 1.5rem;
    height: 35vh;
    align-items: center;
    justify-content: space-evenly;
}