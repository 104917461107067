.ProdajnoMestoCMS {
    width: 94vw;
    background-color: white;
    display: flex;
    padding: 3vh 3vw 0.625rem;
}

.Right{
    /*flex-grow: 1;*/
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: sticky;
    align-self: flex-start;
    top: 0;
    max-width: 55vw;
}

.Updated {
    background-color: #E0FFFF;
}

.Selected {
    background-color: #0F9D58;
}

.Left {
    width: 42vw;
}

.Left div {
    border: 0.125rem solid #8b8989;
    padding-left: 0.5rem;
}

.ProdajnoMestoCMS p {
    font-size: 0.875rem;
}

.ProdajnoMestoCMS .NameOfCurrent {
    color: #0F9D58;
    font-size: 1.563rem;
    margin: 0.5rem;
}

.ImagesUploadHolder {
    display: flex;

}
.ImageUploader {
    margin: 1rem;
    box-shadow: 0 0.125rem 0.188rem #ccc;
    border: 0.063rem solid #eee;
}

