.AddressCard {
    box-sizing: border-box;
    border: 1px solid #e2e8ecff;
    border-radius: 6px;
    min-height: 30px;
    height: max-content;
    padding: 1rem 2rem;
    margin: 0 auto 2rem;
    color: #535358;
    display: flex;
    flex-direction: column;
}

@media screen and (max-width: 699px){
    .AddressCard {
        background-color: #F1F4F6;
        margin: 0 auto 1rem;
    }
}

.AddressHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.AddressHeader h3 {
    font-size: 21px;
    font-weight: 500;
    color: #223d62ff;
    margin: 12px 0;
}

.AddressWrapper {
    min-height: 30px;
    height: max-content;
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
}

.AddressPart {
    width: 85%;
    font-size: 16px;
}

.AddressPart p {
    margin: 8px 0 0;
}

.EditAddress:hover {
    color: #416CAA;
    cursor: pointer;
}