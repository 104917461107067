.ForgotPasswordPane {
    padding-top: 100px;
    display: flex;
    justify-content: center;
}

.ForgotPasswordPaneHolder {
    width: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.InfoMessageHolder {
    height: 3rem;
}

.InfoMessageHolder p {
    margin: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #008D36;
}

@media screen and (max-width: 699px) {
    .ForgotPasswordPane {
        padding: 100px 16px 0;
        width: 90vw;
    }

    .ForgotPasswordPaneHolder {
        width: 100%;
    }
    .ForgotPasswordPaneHolder h2 {
        text-align: center;
    }

}