.MainPageButton button {
    background-color: white;
    padding: 0.3rem .5rem;
    color: #535358;
    display: inline-flex;
    font-size: 1rem;
    align-items: center;
    justify-content: flex-start;
    border: none;
    z-index: 20;
    height: 2.52rem;
    width: 100%;
    border-radius: 6px;
}

.MainPageButtonActive button {
    background-color: white;
    padding: 0.3rem .5rem;
    color: #4D5055 !important;
    display: inline-flex;
    font-size: 1rem;
    align-items: center;
    justify-content: flex-start;
    border: none;
    z-index: 20;
    fill: white;
    /*height: 2.52rem;*/
    width: 100%;
    cursor: pointer;
    border-radius: 6px;
}

.ActiveInDropdown button {
    color: #00B1B0 !important;
    background-color: white !important;
    border-radius: 0;
}

.InDropdown button {
    border-radius: 0;
}

/*.MainPageButtonActive a {*/
/*    color: white;*/
/*    text-decoration: none;*/
/*}*/

.MainPageButton a.active {
    cursor: pointer;
    color: white !important;
    text-decoration: none;
}

.MainPageButton button:hover {
    background-color: #c4eaf5;
    cursor: pointer;
}

@media screen and (min-width: 700px) {
    .MainPageButton button {
        padding: 0.3rem 1rem;
        height: 2.7rem;
    }

    .MainPageButtonActive button {
        padding: 0.3rem 1rem;
        height: 2.7rem;
        width: 100%;
        background-color: #00B1B0;
        color: white !important;
    }
}