.CartHeader {
    padding: 8px 24px;
    border-bottom: 1px solid #e2e8ecff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 45px;
    box-sizing: border-box;
    align-items: center;
}

.CartHeader p {
    font-size: 18px;
    font-weight: bold;
    color: #4a4f54ff
}

.IconStyle {
    color: #4a4f54;
    height: 24px;
    width: 24px;
    stroke-width: 1;
    display: flex;
    align-content: center;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
}
