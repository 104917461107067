.SingleTherapyWrapper {
    display: flex;
}

.SingleTherapy {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    border-radius: 6px;
    border: 1px solid #D0D4D9;
    padding: 24px 16px;
    margin: 1rem 0;
    width: 100%;
}

.SingleTherapy:hover {
    border: 1px solid #00B2B1;
}

.ActionsWrapper {
    display: flex;
}

.TherapyName {
    display: flex;
    align-items: center;
    font-size: 18px;
}

.IconStyle {
    stroke: #8B8C8F;
    height: 18px;
    width: 18px;
    margin: 0 16px;
    cursor: pointer;
}

.IconStyle:hover {
    stroke: #00B2B1;
}

.SponsoredTherapy {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@media screen and (min-width: 700px){
    .SingleTherapyWrapper {
        margin-right: 2rem;
    }
}