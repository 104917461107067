.ArticleInfoPageArticleTextBox {
    /*width: 470px;*/
    width:36%;
    /*height: 450px;*/
    height: 100%;
    padding: 2rem;
    box-sizing: border-box;
    display: flex;
    flex-flow: wrap;
    align-content: space-between;
    background-color: white;
    margin: 0 12px;

    color: #4a4f54;
}

.ArticleInfoPageArticleTextBox p {
    font-size: 1rem;
}