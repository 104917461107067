.TherapyArticles {
    padding-left: 32px;
    box-sizing: border-box;
    width: 50%;
    right: 0;
    top: 0;
    position: absolute;
    border-left: 1px solid #E2E8EC;
    /*overflow-y: auto;*/
    height: 55vh;
}