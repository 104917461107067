.ShopPageHeader {
    top: 70px;
    box-sizing: border-box;
    height: 450px;
    margin: 0 32px;
}

@media screen and (max-width: 699px){
    .ShopPageHeader {
        display: none;
    }
}

.ShopPageHeader .Content {
    display: flex;
    justify-content: space-between;
    height: 100%;
    align-items: center;
}

.ShopPageHeader .ContentLeft {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.ExitAndShopBrandGroup {
    display: flex;
    flex-direction: row;
    margin-top: auto;
}

.ShopPageHeader input {
    height: 2.375rem;
    width: 35rem;
}

.ShopPageHeader .ContentRight {
    display: flex;
    flex-direction: row;
    margin: auto 0;
}

.ShopHQ {
    color: white;
    margin: 0;
    padding-left: 1rem;
    font-size: 1.25rem;
}

.ShopName {
    color: white;
    margin: 0;
    padding-left: 1rem;
    font-size: 2rem;
}

.ShopExitButton {
    font-size: 0.875rem;
    background-color: transparent;
    color: white;
    margin-top: 0.425rem;
    padding: 0.5rem;
    border-radius: 0.625rem;
    border: 1px solid white;
    text-align: center;
}

.ShopExitButton:hover {
    cursor: pointer;
    background-color: white;
    color: #416caa;

}

/*.ExitIcon {*/
/*    fill: white;*/
/*    size: 24px;*/
/*}*/

/*.ExitIcon:hover {*/
/*    fill: #416caa;*/
/*}*/

/*.IconStyle {*/
/*    fill: white;*/
/*    height: 20px;*/
/*    width: 20px;*/
/*    transform: rotate(180deg);*/
/*    !*stroke-width: 1;*!*/
/*    !*margin: auto;*!*/
/*}*/
.IconStyle {
    fill: white;
    height: 32px;
    width: 32px;
    stroke-width: 1;
    transform: rotate(180deg);
    /*margin: auto;*/
}

.CommercialImage {
    display: flex;
    flex-direction: column;
    height: 100%;
    cursor: pointer;
}