/*.ShopInfoBox {*/
/*    background-color: white;*/
/*    border-radius: 6px;*/
/*    width: 350px;*/
/*    height: 180px;*/
/*    padding: 20px;*/
/*    box-sizing: border-box;*/
/*    margin: auto;*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    justify-content: flex-start;*/
/*}*/

/*.ShopInfoBox .UpperPart {*/
/*    height: 130px;*/
/*}*/

.ShopInfoBoxHeader {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 0;
    justify-content: space-between;
}

.ShopInfoHeaderMobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 0.75rem;
}

.DetailsAndBadgesMobile {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0;
}

.ShopName p {
    font-size: 18px;
    font-weight: bold;
    color: #4a4f54ff;
    margin: 0;
}

.ShopName p:hover {
    cursor: pointer;
}

.ShopExitIcon {
    display: flex;
    justify-content: center;
    align-items: center;
}

.ShopExitIcon:hover {
    cursor: pointer;
}

.ShopAddress {
    color: #4a4f54ff;
    font-size: 14px;
    margin: 0;
    padding-bottom: 4px;
}

.ShopPhoneNumber {
    margin: 0;
    color: #4a4f54ff;
    font-size: 14px;
    display: flex;
}

.MobileIcon {
    width: 18px;
    height: 18px;
    stroke:#013A63;
}

.StoreLinkMobile {
    display:flex;
    align-items:center;
    color:#219EBC;
    padding:0.5rem 0;
    font-size:12px;
}

.PharmacyInfoHolderMobile {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 12px;
    border-bottom: 1px solid #E2E6EA;
    border-top: 1px solid #E2E6EA;
}

.WorkingHours {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #4a4f54ff
}


@media screen and (min-width: 700px) {
    .ShopName {
        display: flex;
        align-items: center;
    }

    .ShopName p {
        margin-top: 0;
        text-decoration: underline;
        line-height: 24px;
        font-size: 24px;
    }
    .FirstRow {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

}

.ShopBadges {
    display: flex;
}

.SqBox1,
.SqBox3, .SqBox4,
.SqBox5, .ShopExitIcon {
    position: relative;
}

.BadgeTooltipText {
    /* Position the tooltip */
    left: -10.4rem;
    /*right: 20%;*/
    top: 2.2rem;
}

.BadgeTooltipText::after {
    /* position tooltip correctly */
    left:79%;

    /* vertically center */
    bottom:94%;
}

.SqBox1:hover .BadgeTooltipText,
.SqBox1:hover .BadgeTooltipText:after,
.SqBox3:hover .BadgeTooltipText,
.SqBox3:hover .BadgeTooltipText:after,
.SqBox4:hover .BadgeTooltipText,
.SqBox4:hover .BadgeTooltipText:after,
.SqBox5:hover .BadgeTooltipText,
.SqBox5:hover .BadgeTooltipText:after,
.ShopExitIcon:hover .BadgeTooltipText,
.ShopExitIcon:hover .BadgeTooltipText:after
{
    visibility: visible;
    opacity: 1;
    transition: 600ms ease-out;
}

@media screen and (max-width: 699px){
    .BadgeTooltipText {
        display: none;
    }
}

.InfoWithIconWrapper {
    display:flex;
    margin-bottom: 8px;
}

.RateBadgeText {
    color: white;
    font-weight: 800;
    font-size: 14px;
}

@media screen and (min-width: 700px) {
    .RateBadgeText {
        font-size: 16px;
    }
}