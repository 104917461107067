.SearchWrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 0;
}

.SearchWrapperSmall {
    border-radius: 1.563rem;
    margin: 0;
    /*z-index: 14;*/
/*    TODO ovo treba izmeniti, tj izbeci da se ne koristi important
       ako zelimo da izbegnemo important moramo da razdvojimo searchbar komponente za mainpage i za apppage*/
}

.Content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-top: 1rem;
    height: 4rem;

    /*position: sticky;*/
    /*top: 0;*/
    /*Stocky je da se zalepi za vrh, tj da ostaje uvek na vrhu kada se skroluje*/
}

@media screen and (min-width: 700px) {
    .Content {
        width: 145%;
        height: auto;
    }
}