.StorePage {

}

.PagePart {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.LeftSide {
    width: 400px;
    margin: 32px;
    box-sizing: border-box;

}

.RightSide {
    /*background-color: white;*/
    width: calc(100% - 400px - 64px);
    height: fit-content;
    margin: 32px 32px 0 0 ;
    box-sizing: border-box;
}