.Title {
    margin: 0.5rem 0 0;
    padding: 0 0 1rem 0 ;
    font-size: 18px;
    border-bottom: 1px solid #e2e8ecff;
    color: #00375cff;
}

.Text {
    padding: 0.5rem 0;
    margin: 0;
}

@media screen and (min-width: 700px){
    .Text {
        padding: 1rem 0 0;
    }
}