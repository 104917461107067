.UnknownItem {
    display: flex;
    flex-direction: column;
    font-size: x-large;
    color: #535358;
}

.UnknownItemSearchResult {
    padding: 0 30px;
    display: flex;
    justify-content: space-between;
}

.SearchResult0{
    justify-content: flex-start;
    color: #416CAA;
}

.SearchResultUnavailable{
    justify-content: flex-end;
    color: #E53132;
}

.UnknownItem p {
    font-size: 14px;
}