.AddressFormRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.TextBoxStyle {
    padding-top: 10px;
    height: 2.6rem;
    display: flex;
}

.Label {
    margin-right: 0.5rem;
    padding-top: 0.5rem;
    min-width: 2.5rem;
    font-size: 14px;
    white-space: nowrap;
}

.Input {
    color: #5b6977ff;
    font-size: 16px;
    text-align: start;
    height: 32px;
    border-radius: 3px !important;
    border: 1px solid #e2e8ec;
    width: 100%;
    box-sizing: border-box;
    padding: 20px;
}

.inputVerificationHolder {
    display: flex;
    flex-direction: column;
}

.ErrorMessage {
    color: red;
    font-size: 10px;
    width: 90%;
    margin-top: 0.2rem;
}