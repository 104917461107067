.TotalAmount {
    display: flex;
    justify-content: space-between;
    /*color: #00A4B4;*/
    border-bottom: 1px solid #e2e8ecff;
    margin-bottom: 1rem;
    height: 110px;
}

.TotalAmount h2 {
    margin-top: 0;
    margin-bottom: 26px;
    font-weight: 700;
    font-size: 28px;
}