.SearchedArticleItem {
    width: 100%;
    height: 320px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 2rem;
    margin-right: 64px;
    box-sizing: border-box;
    background-color: white;
    border-radius: 6px;
    border: 1px solid #e2e8ecff;
}

.SearchedArticleItem:hover {
    /*border: 1px solid #013a63ff;*/
    box-shadow: 0 0 1.2rem #acaaaa;
    transition: 400ms ease-out;
}

.SearchedArticleItem .ArticleImageHolder {
    width: 30%;
    display: flex;
    align-items: center;
    position: relative;
}

.ArticleImageHolder .ArticleImage {
    width: 100%;
}

.SearchedArticleItemTextBox {
    width:45%;
    height: 100%;
    padding: 2rem;
    box-sizing: border-box;
    display: flex;
    flex-flow: wrap;
    align-content: space-between;
    background-color: white;
    margin: 0 12px;

    color: #4a4f54;
}

.SearchedArticleItemTextBox p {
    font-size: 1rem;
    width: 100%;
}

.SearchedArticleItemTextBox .Title {
    font-size: 20px;
    padding: 0 1rem;
}

.SearchedArticleItemTextBox .MoreDetailsWrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    border-top: 1px solid #E2EBEC;
    margin-top: 1rem;
    padding-top: 1rem;
}

.MoreDetailsWrapper .MoreDetailsAction {
    cursor: pointer;
    padding: 0 1rem;
    width: fit-content;
    display: flex;
    align-items: center;
    color: #00B2B1;
}

.SearchedArticleItem .ActionsHolder {
    display: flex;
    flex-direction: column;
    width: 25%;
    padding: 2rem;
    box-sizing: border-box;
    position: relative;
}

.QuantityInCart {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -11px;
    right: -11px;
    border-radius: 50%;
    background-color: #219EBC;
    color: white;
    width: 22px;
    height: 22px;
}

.ItemAvailability {
    position: absolute;
    left: 50px;
    top: 50px;
}

.AddSelectedArticleToCartButton {
    display:flex;
    width: 100%;
    position: relative;
    align-items: center;
    justify-content: space-between;
    border-Top: 1px solid #E2EBEC;
    height: 52px;
    padding-top: 1rem;
}

.AddSelectedArticleToCartButton .ShoppingCartIcon {
    width: 24px;
    color: inherit;
    left: 24px;
}

.ActionsHolder .SelectedArticlePrice {
    margin-top: auto;
    display: flex;
    justify-content:space-between;
    align-items: end;
    margin-bottom: 1rem;
}


.TooltipWrapper {
    position: relative;
}

.TooltipText {
    /* Position the tooltip */
    left: 3rem;
    top: 2.7rem;
}

.TooltipText::after {
    /* position tooltip correctly */
    left:10%;
    /* vertically center */
    bottom: 94%;
}

@media screen and (max-width: 699px){
    .ItemAvailability {
        top: -10rem;
    }
}

.ItemAvailabilityTooltipText {
    /* Position the tooltip */
    left: -11rem;
    top: 2.2rem;
}

.ItemAvailabilityTooltipText::after {
    /* position tooltip correctly */
    left:80%;

    /* vertically center */
    bottom: 94%;
}

.TooltipWrapper:hover .TooltipText,
.TooltipWrapper:hover .TooltipText:after,
.ItemAvailability:hover .ItemAvailabilityTooltipText,
.ItemAvailability:hover .ItemAvailabilityTooltipText:after
{
    visibility: visible;
    opacity: 1;
    transition: 600ms ease-out;
}

.SearchedArticleItem .LoyaltyPointsIcon {
    position: absolute;
    top: 50px;
    right: 50px;
}

.OriginalPrice {
    display: flex;
    color: #88959E;
    font-size: 16px;
    text-decoration: line-through;
    justify-content: center;
}

.SqBox {
    position: relative;
}

.TooltipSBText {
    position: absolute;
    visibility: hidden;
    right: -20px;
    /*left: -10.65rem;*/
    top: 38px;
}

.TooltipSBText::after {
    /* position tooltip correctly */
    right:10%;
    /* vertically center */
    bottom: 98%;
}

.SqBox:hover .TooltipSBText,
.SqBox:hover .TooltipSBText:after
{
    visibility: visible;
    opacity: 1;
    transition: 600ms ease-out;
}