.Layout {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    z-index: -2;
    /*width: 100%;*/
    background-color: white;
    min-height: 100vh;
    max-width: 100vw;
}

@media screen and (min-width: 700px){
    .Layout {
        width: 100%;
        justify-content: center;
        height: auto;
        /*background-color: #FBFBFB;*/
    }
}

.LayoutContent {
    /*position: relative;*/
    /*min-height: calc(100vh - 65px);*/   /*  vrednost za stari/prethodni Footer (visine 65px).  */
    min-height: calc(100vh - 352px);
    /*background-color: #F3F6F8;*/
    z-index: 1;
    max-width: inherit;
    width: 100%;
}
.slide-container {

}
