.PlayStoreBoard {
    background-repeat: no-repeat;
}

.PlayStoreBoard .TextPart {
    display: flex;
    flex-direction: column;
    width: 40%;
    padding: 64px;
}

.PlayStoreBoard .TextPart .Title {
    color: #454952;
    font-size: 44px;
}

.PlayStoreBoard .TextPart .Description {
    font-size: 24px;
    padding-bottom: 32px;
}