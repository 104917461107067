.EditInputFiled {
    width: 100%;
    border: 1px solid #f1f4f6ff;
    border-radius: 3px;
    box-sizing: border-box;
    padding: 1rem;
    margin-top: 1rem;
    font-size: 16px;
    color: #5b6977ff;
}

.ButtonsHolder {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.BottomMessageHolder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.BadPasswordMsg {
    color: red;
    font-size: 12px;
}