.TherapyForModal {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    height: 2.875rem;
    border: 1px solid #4D5055;
    border-radius: 6px;
    padding-left: 1rem;
    margin-bottom: 1rem;
    box-sizing: border-box;
}

.TherapyForModalClickable {
    cursor: pointer;
}

.TherapyForModalDisabled {
    border: 1px solid lightgrey !important;
}