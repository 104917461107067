.EditInputFiled {
    width: 100%;
    border: 1px solid #f1f4f6ff;
    border-radius: 3px;
    box-sizing: border-box;
    padding: 1rem;
    margin-top: 1rem;
    font-size: 16px;
    color: #5b6977ff;
}

.FormWrapper {
    width: 100%;
    padding: 0 1rem;
    box-sizing: border-box;
}


@media screen and (min-width: 700px) {
    .FormWrapper {
        font-weight: bold;
        margin-top: 1rem;
        padding: 0;
    }

    .Form {
        display: flex;
    }
}

.Checkbox {
    outline: none;
    border: 0.063rem solid #ccc;
    background-color: white;
    font: inherit;
    padding: 0.375rem 0.625rem;
    display: block;
    width: 100%;
    box-sizing: border-box;
}