.StoreItemsError {
    display: flex;
    flex-direction: column;
    font-size: x-large;
    color: #535358;
    /*background-color: palegoldenrod;*/
}

.SearchResultError {
    padding: 0 30px;
    display: flex;
    justify-content: space-between;
}

.SearchResultErrorMessage{
    padding: 0 30px;
    display: flex;
    background-color: #f5f5f6;
}

.SearchResultErrorMessage .SearchResultErrorIcon {
    width: 24px;
    height: 24px;
    margin: auto 10px;
}

.SearchResult0{
    justify-content: flex-start;
    color: #416CAA;
}

.SearchResultUnavailable{
    justify-content: flex-end;
    color: #E53132;
}

/*.StoreItemsError .EmailInputField {*/
/*    display: flex;*/
/*}*/

/*.StoreItemsError .EmailInputField::placeholder {*/
/*    color: #535358;*/
/*    opacity: 0.4;*/
/*    padding-left: 10px;*/
/*}*/

.StoreItemsError p {
    font-size: 14px;
}

.AdditionalActionsHolder {
    display: flex;
    flex-direction: row;
    padding: 30px;
    /*justify-content: center;*/
    /*margin: auto;*/
}

.StoreItemsError .SupportHolder {
    background-color: white;
    border-radius: 6px;
    box-sizing: border-box;
    width: 15rem;
    height: 15rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #e2e8ecff;
    text-align: center;
    margin-right: 1rem;
}

.LiveChatDisplay {
    display: none !important;
}

@media screen and (min-width: 700px) {
    .LiveChatDisplay {
        display: block !important;
    }
}