.OrderCompletionPageHolder {
    padding: 6rem 20rem 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.OrderCompletionPageMessage {
    padding: 4rem 7rem;
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    /*border: 1px solid #e2e8ecff;*/
    border-radius: 6px;
    font-size: 18px;
    /*box-shadow: 0 0 0.8rem #cccccc;*/
}

.OrderCompletionPage p {
    margin: auto;
}

.OrderCompletionPageHolderMobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 80vh;
}
.OrderCompletionPageMobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
}

.OrderCompletionPageMobile p {
    color: #4D5055;
    text-align: center;
}