/*@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;600&display=swap');*/
@import url('https://maxcdn.icons8.com/fonts/line-awesome/1.1/css/line-awesome.min.css');
@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@300&display=swap');

/*@font-face {*/
/*    font-family: "Montserrat";*/
/*    src: local("Montserrat"), url("./fonts/Montserrat-Regular.ttf") format("truetype");*/
/*    font-weight: normal;*/
/*}*/

/*@font-face {*/
/*    font-family: "Inter";*/
/*    font-style: normal;*/
/*    font-weight: 400;*/
/*    src: local("Inter"),*/
/*    url("./fonts/Inter-VariableFont_slnt,wght.ttf") format("truetype");*/
/*    !*font-weight: normal;*!*/
/*}*/


@font-face {
    font-family: "DigitalFont";
    src: local("FontsFree-Net-DS-DIGI-2"),
    url("./fonts/FontsFree-Net-DS-DIGI-2.ttf")
    format("truetype");
}

body {
    margin: 0;
    /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
    /*  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
    /*  sans-serif;*/
    font-family: 'Lexend', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /*overflow: hidden;*/
    overflow-x: hidden;
}

button {
    font-family: 'Lexend', sans-serif;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:focus {
    outline-color: transparent;
    outline-style: none;
}

a:link {
    color: #416CAA;
    background-color: transparent;
    text-decoration: none;
}

/* Da skrol bude nevidljiv */
/*body::-webkit-scrollbar {*/
/*  display: none;*/
/*}*/

/*ZOOM */
/*#transition-popper id se odnosi na Leave dugme u zoom call-u koje se iz nekog razloga pozicionira ispod video call objekta*/
#transition-popper {
    z-index: 999;
}

/*meetingSDKElement id je div u kome se kreira zoom call window*/
#meetingSDKElement {
    justify-content: center;
}

/*klasa u kojoj se nalazi zoom call window*/
.jss10 {
    position: relative !important;;
}

/*modifikovanje google address dropdown-a*/
.pac-icon {
    display: none;
}

.pac-container:after {
    display: none;
}

