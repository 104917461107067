.SubNavigationMenuWrapper {
    width: 90%;
    margin: auto;
    display: flex;
    justify-content: center;
}

.SubNavigationMenuWrapper nav ul {
    width: 100%;
    display: flex;
    justify-content: center;
    list-style-type: none;
    margin-top: 1rem;
    padding: 0;
    overflow: hidden;
}

@media screen and (min-width: 700px) {
    .SubNavigationMenuWrapper {
        width: 100%;
        height: 3rem;
        /*border-bottom: none;*/
    }

    .SubNavigationMenuWrapper nav ul {
        width: 70rem;
        margin-top: 6rem;
    }
}

nav ul li a {
    display: block;
    color: grey !important;
    text-align: center;
    padding: 16px;
    text-decoration: none;
}

.LinkTextWrapper {
    cursor: pointer;
    width: 100%;
    position: relative;
    /*z-index: 1;*/
    display: flex;
    justify-content: center;

}

.SubNavigation {
    cursor: pointer;
    width: 100%;
    position: relative;
    /*z-index: 1;*/
    display: flex;
    justify-content: center;
    border-bottom: 3px transparent;
}

.SubNavigation:hover:after  {
    content: ""; /* This is necessary for the pseudo element to work. */
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    /*display: block; !* This will put the pseudo element on its own line. *!*/
    margin: 0 auto; /* This will center the border. */
    width: 5px; /* Change this to whatever width you want. */
    padding-top: 20px; /* This creates some space between the element and the border. */
    border-bottom: 3px solid grey; /* This creates the border. Replace black with whatever color you want. */
}

/* ovo se ucitava inicijalno, kada se pristupi strani /myprofile */
.Selected:after {
    content: ""; /* This is necessary for the pseudo element to work. */
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto; /* This will center the border. */
    width: 30px; /* Change this to whatever width you want. */
    padding-top: 20px; /* This creates some space between the element and the border. */
    border-bottom: 3px solid grey; /* This creates the border. Replace black with whatever color you want. */

}

.Selected:focus:after {
    content: ""; /* This is necessary for the pseudo element to work. */
    position: absolute;
    bottom: 0;
    margin: 0 auto; /* This will center the border. */
    width: 30px; /* Change this to whatever width you want. */
    padding-top: 20px; /* This creates some space between the element and the border. */
    border-bottom: 3px solid grey; /* This creates the border. Replace black with whatever color you want. */
    transition: width .2s ease-out;
}
