.Bullet {
    margin: 1.5rem 0.75rem;
    max-width: min-content;
    background-color: #F8F8F8;
    display: flex;
    flex-direction: column;
    text-align: left;
    height: 460px;
    cursor: pointer;
}

.Bullet:hover {
    box-shadow: 0 0.3rem 0.3rem 0 rgba(0,0,0,0.10);
}

.BulletText {
    color: #535358;
    padding: 0 1.25rem;
    margin: 1rem 0;
    display: flex;
    flex-direction: column;
}

.Bullet img {
    width: 21.25rem;
    height: 40%;
}

.Bullet p {
    font-size: 0.938rem;
}