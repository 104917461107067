.ActionMenu {
    background-color: white;
    height: 86px;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border-radius: 6px;
    box-shadow: 0 8px 0.8rem -12px #cccccc;
    margin: -43px 32px 0;
}

.ActionMenuHolder {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 1366px;
    padding: 0.5rem 30px 0.5rem;
    box-sizing: border-box;
    color: #4a4f54ff;
}

/*.ActionMenu p{*/
/*    box-sizing: border-box;*/
/*    align-items: center;*/
/*    align-content: center;*/
/*    padding: 0;*/
/*    font-weight: bold;*/
/*}*/

.PositionSticky {
    top: 70px;
    position: fixed;
    background-color: white;
    /*border-bottom: 1px solid #888888;*/
    border-radius: 0;
    z-index: 15;
    left: 0;
    margin: 0;
    box-sizing: border-box;
    width: 100%;
    justify-content: center;
}