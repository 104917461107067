.ChooseLocationWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}

.ChosenAddress {
    color: #00b1b0;
    display: flex;
    align-items: center;
    width:100%;
    justify-content:space-between;
}

.RemoveAddress {
    cursor: pointer;
    width: 18px;
    color: rgb(255, 174, 0);
}