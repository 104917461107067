.ShoppingCartListItem {
    box-sizing: border-box;
    width: 100%;
    /*background-color: red;*/
    border-bottom: 1px solid #e2e8ecff;
    display: flex;
    flex-direction: row;
    font-size: 14px;
    /*padding: 0 24px;*/
}

.ShoppingCartListItem .Image {
    min-width: 5rem;
    min-height: 5rem;
    padding: 0 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.ShoppingCartListItem .RightPartSideDrawer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 70%;
}

.ShoppingCartListItem .BottomPartSideDrawer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.ShoppingCartListItem .LeftPartSideDrawer {
    display: flex;
    flex-direction: column;
    width: 30%;
}

.ShoppingCartListItem .RightPartShopPage {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 70%;
}

.ShoppingCartListItem .RightPart {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-evenly;

}

/*style={{display:"flex", flexDirection:"column", justifyContent:"space-between", width:"70%"}}*/

.RightPartShopPage p {
    margin-top: 1rem;
    font-weight: 700;
}

.Quantity {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.QuantityText {
    font-size: 0.875rem;
    border: 2px solid #00B2B1;
    border-radius: 6px !important;
    width: 36px;
    height: 32px;
    box-sizing: border-box;
    text-align: center;
    font-weight: 700;
}

.Button {
    background-color: transparent;
    color: #4a4f54ff;
    border: none;
    cursor: pointer;
}

.IconStyle {
    color: #4a4f54;
    height: 18px;
    width: 18px;
    stroke-width: 1;
    display: flex;
    align-content: center;
}

.ItemAvailability {
    position: absolute;
    left: 0;
    top: 0;
}

.ItemPrice {
    font-size: 18px;
    display: flex;
    justify-content: end;
    align-items: center;
}

.ItemPriceQuestionMark {
    position: relative;
}

.TooltipText {
    /* Position the tooltip */
    left: -176px;
    top: 32px;
}

.TooltipText::after {
    /* position tooltip correctly */
    left:80%;

    /* vertically center */
    bottom: 94%;
}

.ItemPriceQuestionMark:hover .TooltipText,
.ItemPriceQuestionMark:hover .TooltipText:after
{
    visibility: visible;
    opacity: 1;
    transition: 600ms ease-out;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.LoyaltyPointsIcon {
    display: flex;
    justify-content: flex-end;
}