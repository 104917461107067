.ActionMenuRes {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 0.5rem 0.5rem;
    position: relative;
    border-top: 1px solid #e2e8ecff;
}

.PositionSticky {
    top: 44px;
    position: fixed;
    background-color: white;
    border-radius: 0;
    z-index: 6;
    left: 0;
    margin: 0;
    box-sizing: border-box;
    width: 100%;
    border-bottom: 1px solid #e2e8ecff;
}