.Logo {
    background-color: transparent;
    padding: 8px;
    height: 2.75rem;
    box-sizing: border-box;
    border-radius: 5px;
    z-index: 30;
    cursor: pointer;
}

.Logo img {
    height: 100%;
}

@media only screen and (min-width: 700px) {
    .Logo {
        height: 3.5rem;
    }
}