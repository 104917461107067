.PdfContainer {
    width: 100%;
    display: flex;
    justify-content: center;
}

@media screen and (min-width: 700px) {
    .PdfContainer {
        padding-top: 100px;
    }
}

@media print {
    .PdfFile {
        display: none;
    }
}