.StoreInModalWrapper {
    display:flex;
    justify-content: space-between;
    border-bottom:1px solid #E2E8EC;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    padding-bottom: 0.5rem;
    position: relative;
    align-items: center;
    margin-right: 1rem;
}

.StoreInModalWrapper .SubWrapper {
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.StoreInModalRadioButtonWrapper {
    position: absolute;
    right: 0;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border: 1px solid #00B1B0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.StoreInModalRadioButton {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: 1px solid #00B1B0;
}

.StoreInModalRadioButtonWrapperSelected {
    border: 1px solid #00B1B0;
}

.StoreInModalRadioButtonSelected {
    background-color: #00B1B0;
    border: 1px solid #00B1B0;
}