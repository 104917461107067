.QuantityButton {
    height: 24px;
    width: 24px;
    font-size: 22px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    cursor: pointer;
}

.QuantityInput {
    height:34px !important;
    width: 34px !important;
    text-align: center;
    padding: 3px !important;
    border: 1px solid #00B2B1 !important;
    border-radius: 6px !important;
}

/* uklanjanje strelica iz number input fielda */
/* Chrome, Safari, Edge, Opera */
.QuantityInput::-webkit-outer-spin-button,
.QuantityInput::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.QuantityInput[type=number] {
    -moz-appearance: textfield;
}