.LoyaltyBadge {
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
    box-sizing: border-box;
    border-radius: 6px;
    border: 2px solid #FFAE00;
    position: relative;
    padding: 2px 4px;
    background-color: #121010;
    width: 50px;
    height: 25px;
    cursor: pointer;
}

.LoyaltyBadge .Number {
    font-family: "DigitalFont", sans-serif;
    color: white;
    font-size: 18px;
    font-weight: bold;
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.TooltipWrapper {
    position: relative;
}

.TooltipText {
    /* Position the tooltip */
    right: -15px;
    top: 2.3rem;
}

.TooltipText::after {
    /* position tooltip correctly */
    right:10%;
    /* vertically center */
    bottom: 98%;
}

.LoyaltyBadge:hover .TooltipText,
.LoyaltyBadge:hover .TooltipText:after
{
    visibility: visible;
    opacity: 1;
    transition: 600ms ease-out;
}

@media screen and (min-width: 700px){
    .LoyaltyBadge {
        /*border-radius: 6px;*/
        /*height: 95px;*/
        width: 60px;
        height: 30px;
    }

    .LoyaltyBadge .Number {
        font-size: 20px;
    }
}

.TooltipModal {
    width: 90vw;
    height: fit-content;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}