.RFZOButtonWrapper {
    display: flex;
    align-items: center;
    border: 1px solid #4D5055;
    border-radius: 6px;
    padding: 4px 6px;
    text-decoration: none;
    width: fit-content;
    color: #4D5055;
    cursor: pointer;
}

.RFZOMobileButtonWrapper {
    display: flex;
    align-items: center;
    border: 1px solid #4D5055;
    border-radius: 50%;
    padding: 5px;
    text-decoration: none;
    width: fit-content;
    color: #4D5055;
    cursor: pointer;
}