.Article {
    position: relative;
    display: flex;
    flex-direction: column;
    /*justify-content: center;*/
    /*align-items: center;*/
    font-size: 0.875rem;
    background-color: white;
    border-radius: 6px;
    padding: 1rem 1.5rem;
    color: #4a4f54ff;
}

.ArticleInModal {
    position: relative;
    display: flex;
    flex-direction: column;
    font-size: 0.875rem;
    background-color: white;
    padding: 1rem 1.5rem;
    margin-top: -1rem;
}

.Article .MiniBox {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-radius: 6px;
    height: 9.25rem;
    width: 100%;
}

.ArticleHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.375rem 0;
}

.ArticleInModal .ArticleHeaderInMobile {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.ArticleInModal .ArticleTitleInModal {
    color: #416caa;
    font-size: 1rem;
    display: flex;
    justify-content: center;
    word-break: break-word;

}

.ArticleImageHolder {
    box-sizing: border-box;
    height: auto;
    max-width: 70vw;
    /*margin: auto;*/
    display: flex;
    justify-content: center;
    align-items: center;
}

.ArticleImage {
    /*border-radius: 0.5rem;*/
    max-width: 100%;
    max-height: 100%;
    display: block;
    border-radius: 8px;
    object-fit: contain;
}

.ArticleInModal .ArticleImageInModalHolder {
    margin: 5% auto;
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 15rem;
}


.ArticleInModal .ArticleImageInModal {
    max-height: 100%;
    max-width: 90%;
    border-radius: 8px;
}

.ArticleBigImageHolder {
    position: absolute;
    /*right: 1.438rem;*/
    right: -0.5rem;
    width: 25rem;
    height: 25rem;
    border-radius: 8px;
    z-index: 999;
    animation: growingImage 500ms ease-in;
    background-color: white;
}

.ArticleBigImage {
    position: absolute;
    max-width: 100%;
    /*height: 100%;*/
    box-shadow: 0 0 0.8rem #cccccc;
}

@keyframes growingImage {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.ArticleTitle {
    color: #4D5055;
    font-size: 1rem;
    font-weight: bold;
    word-break: break-word;
    text-align: center;
    margin-top: 0;
    text-decoration: underline;
    cursor: pointer;
    width: 100%;
}

.ArticleInfoActions {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 0 0 1rem;
    width: 100%;
}

.Article .MoreDetails {
    display: none;
}

.Article .PriceRange {
    font-weight: normal;
    font-size: 0.75rem;
    margin: 0;
}

@media only screen and (min-width: 700px) {
    .Article {
        padding: 1rem 1rem;
        box-sizing: border-box;
        height: 100%;
    }

    .Article .MiniBox {
        height: 100%;
    }

    .Article .ArticleHeader {
        padding: 0.8rem 0;
    }

    .Article .ArticleImageHolder {
        position: relative;
        height: auto;
        width: 50%;
        margin: 1rem;
    }

    .Article .ArticleTitle {
        color: #4a4f54ff;
        font-size: 1.125rem;
        text-decoration: none;
        margin-top: 18px;
    }

    .Article .MoreDetails {
        color: #4a4f54ff;
        display: flex;
        font-size: 1.125rem;
        /*text-align: end;*/
        cursor: pointer;
        padding-right: 1rem;
        margin: 0 0 0.625rem;
    }

    .Article .PriceRange {
        font-size: 0.875rem;
    }

    .ArticleInfo {
        width: 55%;
        justify-content: space-between !important;
    }
}

.Article .PriceAndDetailsWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    /*margin-top: -0.93rem;*/
}

.PriceAndDetailsWrapperInModal {
    display: flex;
    justify-content: center;
}

.Article .ArticleBody {
    display: block;
    margin-top: 1rem;
}

.Article .ArticleBody button {
    float: right;
    border-radius: 25px;
    padding: 0.3rem;
    border: 1px solid #535358;
    color: #4a4f54ff;
    background-color: transparent;
}

.Article .ArticleBody button:hover {
    color: #4a4f54ff;
    background-color: #eeeeee;
    cursor: pointer;
    transition: 400ms ease-in-out;
}

.ArticleInfo {
    display: flex;
    flex-direction: column;
    /*justify-content: space-between;*/
    /*width: 55%;*/
    margin-left: 1rem;
    margin-right: 1rem;
    align-items: center;
    justify-content: center;
    /*display: flex;*/
    /*flex-direction: column;*/
}

.Article .ArticleInfoActions {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #e2e8ec;
    padding-top: 1rem;
}

.ArticleInfoHandler {
    cursor: pointer;
    color: #00B2B1;
    display: flex;
    align-items: center;
}

.Article .LoyaltyPointsIcon {
    position: absolute;
}

@media screen and (max-width: 699px){
    .LoyaltyPointsIcon {
        position: absolute;
        top: 20%;
        left: 0;
    }
}


