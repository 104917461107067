.ArticleItem {
    /*box-shadow: 0 0 0.8rem #cccccc;*/
    /*box-shadow: rgb(239, 239, 239) 0.313rem 0.313rem 1.313rem 0.375rem;*/
    background-color: white;
    background-blend-mode: overlay;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /*align-items: center;*/
    margin-bottom: 30px;
    padding: 0.2rem 0.3rem 1rem 0.3rem;
    border: 1px solid #e2e8ecff;
    position: relative;
    border-radius: 6px;
    width: 43vw;
    margin-right: 0.75rem;
    margin-left: 0.75rem;
}

.SearchedArticle {
    /*border: 2px solid #88959eff;*/  /* ff na kraju verovatno greskom dodato - treba #88959e */
    border: 2px solid #abb4bb;  /* postavljena malo svetlija nijansa, na zahtev Ferenca */
}

.ArticleItem:hover{
    /*border: 1px solid #adadad;*/
    box-shadow: 0 0 1.2rem #acaaaa;
    transition: 400ms ease-out;
}

/*.ArticleItem:hover {*/
/*    !*border: 1px solid #416CAA;*!*/
/*    !*border: 2px solid rgba(65, 108, 170, 0.5);*!*/
/*    background-color: #faf9f1;*/
/*    transition: 400ms ease-out;    */
/*}*/

.Selected {
    background-color: #f5f5f6;
    /*border: 1px solid #416CAA;*/
    box-shadow: 0 0 1.2rem #898787;
}

.ItemPicture {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
}

.ItemPicture img {
    max-height: 100%;
    max-width: 100%;
}

.ArticleNameWrapper {
    height: 3.58rem;
    width: 100%;
    margin: 0 auto;
}


.ArticleItem .ArticleName {
    color: black;
    font-size: 12px;
    margin-bottom: 0.5rem;
    text-align: left;
    padding: 0 0.5rem 0;
    /*cursor: pointer;*/
}

/*.StoreItemListView h2 {*/
/*    !*color:  #416CAA;*!*/
/*    color: black;*/
/*    font-size: large;*/
/*    !*font-size: 1.125rem;*!*/
/*    white-space: nowrap;*/
/*    width: 17rem;*/
/*}*/

.ArticleItem .ItemPrice {
    color: #121010;
    font-size: 14px;
    margin: 0;
}

.ArticleItem .OriginalPrice {
    color: #88959E;
    text-decoration: line-through;
    font-size: 14px;
    margin: 0;
    padding-left: 8px;
}

.ArticleItem .RsdSpan {
    font-size: 12px;
    margin: 0
}

.ArticleItemPriceRegulativa {
    font-size: 12px;
    color: #4D5055;
}

.BottomLineWrapper {
    width: 82%;
    display: flex;
    flex-direction: row;
    align-items: center;
    bottom: 1rem;
    position: absolute;
    justify-content: center;
    height: 2rem;
    border-top: 1px solid lightgray;
    padding-top: 1rem;
}

.ArticleItem .MoreDetails {
    /*font-size: 12px;*/
    display: none;
}

.StoreItemListView p {
    color: #535358;
    font-size: 1rem;
}

.StoreItem .StoreItemGridView .DisplaySpaceBetweenGridView {
    display: flex;
    vertical-align: center;
}

.StoreItem .StoreItemListView .DisplaySpaceBetweenListView {
    /*display: flex;*/
    /*flex-direction: row;    */
    /*align-items: center;*/
}


.AdditionalInfo {
    width: 100%;
    box-sizing: border-box;
    padding: 0 0.5rem;
    margin-top: auto;
}

.AdditionalInfoListView {
    width: 15rem;
    /*position: relative;*/
    display: flex;
    flex-direction: row;
    /*justify-content: flex-end;*/
    justify-content: space-between;
    align-items: center;
}

.LocationAndAddress {
    width: 25rem;
}

.ListViewPhoneNumber {
    width: 12rem;
}

/*.AdditionalInfo .OpenClose {*/
/*    display: flex;*/
/*    justify-content: flex-start;*/
/*}*/

/*.AdditionalInfo .Stanje {*/
/*    display: flex;*/
/*    justify-content: flex-end;*/
/*}*/


.AdditionalInfoGridView .Access,
.AdditionalInfoListView .Access {
    color: #008D36;
    /*font-size: 0.875rem;*/
    /*margin-top: 2px;*/


    /*margin: 8px 0;*/
}

.AdditionalInfo .Denied {
    color: #E53132;
    /*font-size: 0.875rem;*/
    /*margin-top: 2px;*/
}

/*.AdditionalInfo .VerticalLine {*/
/*    color: #008D36;*/
/*    margin-left: 0.313rem;*/
/*    margin-right: 0.313rem;*/
/*    font-weight: bold;*/
/*}*/

.StoreItemListView i,
.StoreItemGridView i{
    line-height: 0.4;
}

.StoreItemGridView:hover .TooltipText {
    visibility: visible;
    opacity: 1;
    transition: 600ms ease-out;
}

.IconStyle {
    /*fill: white;*/
    height: 16px;
    width: 16px;
    stroke-width: 1;
    padding-right: 0.25rem;
    /*margin: auto;*/
}

.ArticleItem .BottomHolder {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
}

.ArticleInfoIcon:hover {
    cursor: pointer;
}

.ArticleItem  .PriceButton {
    color: #416CAA;
    font-size: 1.125rem;
    min-height: 3.58rem;
    text-decoration: underline;
    cursor: pointer;
}

.ItemAvailability {
    position: absolute;
    left: 14px;
    top: 14px;
}

.LoyaltyPointsIcon {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
}


@media screen and (min-width: 700px) {
    .ArticleItem {
        width: 18.938rem;
        height: 408px;
        margin-right: 30px;
        margin-left: 0;
        padding: 1rem 1.3rem 1rem 1.3rem;
    }

    .ItemPicture {
        height: 13rem;
        padding: 1rem;
    }

    .ArticleItem .ArticleName {
        font-size: 16px;
        font-weight: 700;
    }

    .ArticleItem .ItemPrice {
        font-size: 1.125rem;
    }

    .ArticleItemPriceRegulativa {
        font-size: 16px;
    }

    .BottomLineWrapper {
        justify-content: flex-start;
    }

    .ArticleItem .MoreDetails {
        cursor: pointer;
        font-size: 16px;
        color: #219EBC;
    }
    .ArticleItem .RsdSpan {
        font-size: 16px;
        margin: 0
    }

    .ArticleItem .LoyaltyPointsIcon {
        top: 14px;
        right: 14px;
    }
}

.ItemAvailabilityTooltipText {
    /* Position the tooltip */
    left: -11rem;
    top: 2.2rem;
}

.ItemAvailabilityTooltipText::after {
    /* position tooltip correctly */
    left:80%;

    /* vertically center */
    bottom: 94%;
}

.ItemAvailability:hover .ItemAvailabilityTooltipText,
.ItemAvailability:hover .ItemAvailabilityTooltipText:after
{
    visibility: visible;
    opacity: 1;
    transition: 600ms ease-out;
}


.PriceAndDetailsHolder {
    /*position: absolute;*/
    /*bottom: 3rem;*/
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 24px;
    /*border-bottom: 1px solid #e2e8ecff;*/
}

.RpBadge {
    position: absolute;
    right: 0;
    top: 0;
    width: 0;
    height: 0;
    border-top: 60px solid transparent;
    border-bottom: 60px solid transparent;
    border-left: 60px solid #fc6d6dff;
    /*transform: rotate(-45deg);*/
}

.QuantityInCart {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -11px;
    right: -11px;
    border-radius: 50%;
    background-color: #219EBC;
    color: white;
    width: 22px;
    height: 22px;
}

.SqBox {
    position: relative;
}

.TooltipText {
    position: absolute;
    visibility: hidden;
    right: -20px;
    /*left: -10.65rem;*/
    top: 38px;
}

.TooltipText::after {
    /* position tooltip correctly */
    right:10%;
    /* vertically center */
    bottom: 98%;
}

.SqBox:hover .TooltipText,
.SqBox:hover .TooltipText:after
{
    visibility: visible;
    opacity: 1;
    transition: 600ms ease-out;
}