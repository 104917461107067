.UserInfo {
    display: flex;
    flex-direction: column;
    color: #535358;
}

.Filed {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: .5rem;
    margin-top: .5rem;
    position: relative;
    width: 100%;
    color: #4D5055;
}

.FiledNameAndSurname {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: .5rem;
    margin-top: .5rem;
    position: relative;
    width: 100%;
}

.Label {
    margin: 1.5rem 0 1rem;
    font-size: 18px;
}

.Information {
    padding-left: 1rem;
    font-size: 16px;
}

.NameAndSurname {
    color: #013A63;
    font-size: 18px;
    font-weight: bold;
    margin-top: 1rem;
    margin-bottom: .5rem;
}

.NameAndSurnameEditButton {
    position: absolute;
    right: 0;
    top: 12px;
    background-color: transparent;
    border-radius: 6px;
}

.PhoneNumberEditButton {
    position: absolute;
    right: 0;
    top: -6px;
    background-color: transparent;
    border-radius: 6px;
}

@media screen and (min-width: 700px) {
    .Filed {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #e2e8ecff;
        border-radius: 6px;
        padding-left: 1rem;
        height: 60px;
    }

    .Label {
        margin: 1.5rem 0 1rem;
        font-size: 18px;
    }

    .Information {
        padding-left: 1rem;
        font-size: 16px;
    }
}