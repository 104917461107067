.ProdajnoMestoForm {
    width: 97%;
    text-align: center;
    box-shadow: 0 0.125rem 0.188rem #ccc;
    border: 0.063rem solid #eee;
    padding: 0.625rem;
    box-sizing: border-box;
    overflow-y: scroll;
    height: 55vh;
}

.ProdajnoMestoForm div {
    display: flex;
    align-items: baseline;
}

.ProdajnoMestoForm p {
    color: #888888;
    width: 30%;
    text-align: left;
}
