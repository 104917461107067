.Order {
    border-radius: 3px;
    height: max-content;
    border: 1px solid #e1e5e9ff;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    margin-top: 2rem;
    color: #4d5055ff;
}

.Order:hover {
    -moz-box-shadow: 3px 3px 10px #e1e5e9ff;
    -webkit-box-shadow: 3px 3px 10px #e1e5e9ff;
    box-shadow: 3px 3px 10px #e1e5e9ff;
}

.OrderHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 117px;
}

.OrderHeader .OrderInfoPart {
    padding: 1rem .5rem;
    display: flex;
    justify-content: space-between;
    width: 75%;
    height: 100%;
    box-sizing: border-box;
}

.OrderInfoPartSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.OrderInfoPartSectionTitle {
    font-size: 14px;
    margin-bottom: 5px;
}

.OrderInfoPartSectionAddress {
    font-size: 12px;
    cursor: pointer;
    stroke: #4a4f54ff;
    display: flex;
    align-items: center;
    text-decoration: underline;
}

@media screen and (min-width: 700px) {
    .OrderHeader .OrderInfoPart {
        padding: 1rem 1.5rem;
        display: flex;
        justify-content: space-between;
        width: 75%;
        height: 100%;
        box-sizing: border-box;
    }

    .OrderInfoPartSection {
        align-items: center;
    }

    .OrderInfoPartSectionTitle {
        font-size: 18px;
        margin-bottom: 10px;
    }

    .OrderInfoPartSectionAddress {
        font-size: 18px;
        display: flex;
        align-items: flex-start;
    }

    .OrderInfoPartSectionAddress:hover {
        color: #00B1B0;
        stroke: #00B1B0 !important;
    }
}

.OrderHeader .FeedbackPart {
    padding: 1rem 1.5rem;
    float: right;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25%;
    border-left: 1px solid #e1e5e9ff;
    height: 100%;
    box-sizing: border-box;
}

.OrderBody {
    display: flex;
}

.OrderBody .OrderList {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.OrderBody .OrderList table {
    border: none;
    border-collapse: collapse;
    width: 100%;
}

.OrderBody .OrderList tr {
    border-top: 1px solid #e1e5e9ff;
}

.OrderBody .OrderList th, td {
    padding: 12px;
}

.OrderBody .OrderTotal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 25%;
    border-left: 1px solid #e1e5e9ff;
    border-top: 1px solid #e1e5e9ff;
    box-sizing: border-box;
}

.OrderBody .OrderTotal .TotalAmount {
    font-size: 24px;
    margin: 0;
}

.TotalAmount {
    font-weight: bold;
}

.OrderTotalMobile {
    display: flex;
    justify-content: space-between;
    background-color: #F1F4F6;
    padding: 0.5rem;
}

.CollapsedOrderList {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0.5rem;
}

@media screen and (min-width: 700px){

    .OrderBody .OrderList th, td {
        padding: 18px;
    }

    .CollapsedOrderList {
        justify-content: flex-start;
        width: 73%;
        font-size: 18px;
        padding: 0 0 0 26px;
        height: 112px;
    }
}

.CollapsedOrderList:hover {
    cursor: pointer;
}

.MyDropdownSlidedown {
    display: flex;
    width: 75%;
}

.BlinkOrange {
    width: 200px;
    height: 40px;
    background-color: orange;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 15px;
    border-radius: 6px;
    margin-top: auto;
    margin-left: auto;
}

.BlinkOrange span {
    font-size: 14px;
    color: white;
    animation: blink .75s alternate infinite;
}

.BlinkRed {
    width: 200px;
    height: 40px;
    background-color: red;
    padding: 15px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 15px;
    border-radius: 6px;
    margin-top: auto;
    margin-left: auto;
}

.BlinkRed span {
    font-size: 14px;
    color: white;
    animation: blink .75s alternate infinite;
}

.BlinkGreen {
    width: 250px;
    height: 40px;
    background-color: green;
    padding: 15px;
    text-align: center;
    display: flex;
    align-items: center;
    border-radius: 6px;
    margin-top: auto;
    margin-left: auto;
}

.BlinkGreen span {
    font-size: 14px;
    color: white;
    animation: blink .75s alternate infinite;
}

@keyframes blink {
    0% {
        opacity: 0;
    }
    50% {
        opacity: .5;
    }
    100% {
        opacity: 1;
    }
}

.NoteForUser {
    display: flex;
    flex-direction: column;
    margin: 1rem;
    padding: 1rem;
    border-radius: 6px;
    border: 1px solid #e1e5e9ff;
    background-color: #EFF4F6;
}

.OrderRatingBadge {
    display: flex;
    align-items: center;
    color: #013A63;
    margin: 8px 0;
}