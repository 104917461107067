.ProdajnoMestoForm {
    position: fixed;
    top: 15%;
    left: calc(50% - 160px);
    padding: 2rem;
    background-color: white;
    border: 1px solid #416CAA;
}

.ProdajnoMestoRadnoVreme input {
    padding: 2px;
    margin: 0 0 2px;
}

.ProdajnoMestoRadnoVreme Input {
    margin: 0;
}

.ProdajnoMestoRadnoVreme p {
    font-size: 14px;
}

.ProdajnoMestoRadnoVreme .XButton {
    float: right;
    width: 24px;
    height: 24px;
}

.ProdajnoMestoRadnoVreme .XButton:hover {
    color: #416CAA;
}