.ShopInfoBox {
    background-color: white;
    border-radius: 6px;
    padding: 1rem 2rem;
    box-sizing: border-box;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;

}

.ShopInfoBox .UpperPart {
    height: 130px;
}

.ShopHQ {

}

.ShopInfoBoxHeader {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

}

.ShopName {
    width: 80%;
}

.ShopName p {
    font-size: 18px;
    font-weight: bold;
    line-height: 24px;
    text-decoration: underline;
    margin-top: 0;
    color: #4a4f54ff;
}

.ShopName p:hover {
    cursor: pointer;
}

.ShopExitIcon {
    width: 20%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: -1rem;
}

.ShopExitIcon:hover {
    cursor: pointer;
}

.ShopAddress {
    margin-top: 0;
    color: #4a4f54ff;
    font-size: 14px;
}

.ShopPhoneNumber {
    color: #4a4f54ff;
    font-size: 14px;
    display: flex;
    align-items: center;
    margin: 0;
}

/*.ShopInfoBox .LowerPart {*/
/*    width: 100%;*/
/*    height: 48px;*/
/*    display: flex;*/
/*    align-items: center;*/
/*    border-top: 1px solid #bdccd4ff;*/
/*    margin-bottom: -20px;*/
/*    !*margin-top: -.5rem;*!*/
/*}*/

.LowerPart {
    display: flex;
    border-top: 1px solid #bdccd4;
    align-items: center;
    margin-top: 1rem;
    padding-top: 1rem;
}

.LowerPart .WorkingHours {
    width: 40%;
    display: flex;
    align-items: center;
    color: #4a4f54ff;
}

.LowerPart .ShopBadges {
    width: 60%;
    display: flex;
    justify-content: flex-end;
}

.XButton {
    max-height: 1.688rem;
    border: none;
    padding: 0;
    border-radius: 1.563rem;
    background-color: white;
    /*border: 1px solid #80808099;*/
    width: 24px;
    height: 24px;
    cursor: pointer;
    font-weight: bold;
}

.SqBox5 {
    cursor: pointer;
}

.SqBox1, .SqBox2,
.SqBox3, .SqBox4,
.SqBox5, .ShopExitIcon,
.XButton {
    position: relative;
}