.WrapperStyle {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 1rem;
}

.RFZOArticleList {
    width: 90vw;
}

@media screen and (min-width: 700px){
    .WrapperStyle {
        margin-top: 5rem;
    }

    .RFZOArticleList {
        width: 70%;
    }
}