.NewTherapyBox {
    border-top: 1px solid lightgrey;
    box-sizing: border-box;
}

.TherapyList {
    width: 100%;
    margin: 1rem 0;
    box-sizing: border-box;
}

.NewTherapyBox .TherapyNameInputField {
    width: 90%;
    height: 2.875rem;
    border: 1px solid #4D5055;
    border-radius: 6px;
    padding-left: 1rem;
    box-sizing: border-box;
}