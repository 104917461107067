.NavigationItems {
    list-style: none;
    display: flex;
    align-items: center;
    height: 100%;
    position: relative;
}

.IconStyle {
    color: #4a4f54;
    height: 24px;
    width: 24px;
    stroke-width: 1;
    display: flex;
    align-content: center;
    padding-right: 12px;
    padding-left: 6px;
}

.IconStyle:hover {
    color: grey;
}

.UserLoginBox {
    border-radius: 50%;
    color: white;
    font-size: 1rem;
    font-weight: bold;
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.LoginText {
    cursor: pointer;
    color: #4a4f54;
    font-size: 1rem;
    text-decoration: underline;
    white-space: nowrap;
}

.LoginText:hover {
    color: grey;
}

/*.UserLoginBox:hover {*/
/*    color: #416CAA;*/
/*}*/

.AddToCartAnimation {
    /*color: #4a4f54;*/
    /*height: 24px;*/
    /*width: 24px;*/
    /*stroke-width: 1;*/
    /*display: flex;*/
    /*align-content: center;*/
    /*padding-right: 0.5rem;*/
    /*padding-left: 0.5rem;*/
    animation: wiggle 200ms 200ms 3;
}

@keyframes wiggle {
    0% {
        transform: translateX(0);
    }
    50% {
        transform: translateX(-4px);
    }
    100% {
        transform: translateX(4px);
    }
}

.MainPageShoppingCartIcon {
    position: relative;
    border: 1px solid #4D5055;
    padding: 4px 0;
    border-radius: 6px;
    margin-left: 0.5rem;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    height: 38px;
}

@media screen and (min-width: 700px){
    .MainPageShoppingCartIcon {
        margin-left: 1rem;
    }
}

/*targeting not safari (samo Chrome i Mozzila)*/
/*@supports (contain: paint) {*/
/*    .MainPageShoppingCartIcon {*/
/*        padding: 6px 0;*/
/*    }*/
/*}*/

/*@media not all and (min-resolution:.001dpcm) { @media {*/

/*    .MainPageShoppingCartIcon {*/

/*        padding: 0;*/

/*    }*/
/*}}*/


.YouHaveActiveCartBalloon {
    top: 35px;
    right: 6px;
    width: 200px;
    height: 55px;
    font-size: 14px;
    border-radius: 8px;
    /*opacity: .8;*/
    cursor: pointer;
}

.YouHaveActiveCartBalloon::after {
    display: none;
}

.MainPageShoppingCartIcon:hover .YouHaveActiveCartBalloon {
    visibility: visible;
    opacity: 0.8;
    transition: 600ms ease-out;
}