.SideDrawer {
    position: fixed;
    width: 17%;
    height: 100%;
    right: 20px;
    z-index: 30;
    background-color: white;
    box-sizing: border-box;
    transition: transform 0.3s ease-out;
    border-radius: 6px;
}

.Open {
    transform: translateX(0%);
}

.Close {
    transform: translateX(120%);
}