.AlertWrapper {
    padding: 8px;
    font-weight: bold;
    display: flex;
    align-items: center;
}

@media screen and (max-width: 699px) {
    .AlertWrapper {
        margin-left: 1rem;
    }
}

.WarningAlert {
    border: 1px solid #F58300;
    color: #F58300;
}