.MyProfilePaneHolder {
    padding: 0 1rem;
}

.MyProfilePane {
    display: flex;
    flex-direction: column;
    justify-content: left;
    background-color: white;
    border-radius: 6px;
    font-size: 14px;
}

@media screen and (min-width: 700px){
    .MyProfilePaneHolder {
        padding: 8.25rem 32px;
    }

    .MyProfilePane {
        padding: 2rem 70px;
        border: 1px solid #e2e8ecff;
        min-height: 40vh;
    }
}