.CounterWrapper {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;
    padding: 0.5rem;
    margin: 0;
    width: 130px;
    height: 6.875rem;
    position: relative;
}

.ArrowRight {
    position: absolute;
    width: 24px;
    bottom: 14px;
    right: 8px;
}

@media screen and (max-width: 699px) {
    .CounterWrapper {
        /*box-shadow: 2px 2px 0.5rem #a1a0a06b;*/
        /*border: 1px solid #a1a0a06b;*/
        /*border-radius: 6px;*/
        width: 100px;
        height: 85px;
    }

    .ArrowRight {
        width: 18px;
        bottom: 7px;
        right: 6px;
    }
}

.CounterSubtitle {
    font-size: 0.875rem;
    text-align: center;
    width: 100%;
}

.Clickable {
    cursor: pointer;
}

.Clickable:hover {
    color: #888888;
}