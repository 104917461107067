.ShoppingCartList {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100% - 94px - 75px);
    box-sizing: border-box;
    /*padding: 0 24px;*/
}

.ShoppingCartListScrollbar {
    height: 100%;
    overflow-y: hidden;
}

.ConfirmationScrollBar {
    /*height: 40vh;*/
    /*overflow-y: hidden;*/
    margin-bottom: 1rem;
}

.ShoppingCartList .TotalSideDrawer {
    height: max-content;
    display: flex;
    justify-content: space-between;
    padding: 0 24px;
    font-size: 20px;
    font-weight: 700;
}

.ShoppingCartList .TotalShoppingCartPage {
    display: none;
}

.ShoppingCartListFinalView {

}

.TotalConfirmation {
    display: none;
    /*display: flex;*/
    /*justify-content: space-between;*/
    /*padding: 0 0.625rem;*/
}