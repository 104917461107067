.EnterPhoneNumberWrapper {
    padding: 1rem 1rem 1rem;
    width: 90vw;
}

@media screen and (min-width: 700px) {
    .EnterPhoneNumberWrapper {
        border-top: 1px solid #e2e8ecff;
        border-bottom: 1px solid #e2e8ecff;
        width: 100%;
        padding: 1rem 0 1rem;
    }
}