.SearchResultHeader {
    z-index: 10;
    box-sizing: border-box;
    height: 450px;
    margin: 0 32px;
}

.SearchResultHeaderSticky {
    /*top: 5.25rem;*/
    padding: 1rem 1.5rem 2rem;
    width: 100%;
    position: fixed;
    box-sizing: border-box;
    /*margin-top: 5rem;*/
    background-color: white;
    box-shadow: 0 0 0.8rem #cccccc;
    z-index: 10;
}

.SearchResultHeaderHolder {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: auto;
    /*width: 75rem;*/
    height: 100%;
    align-items: center;
    /*transform: scaleX(-1);  !* skinuti kada se skine i scale sa .SearchResultHeader, gore *!*/
    position: relative;
}

.RightHeaderPane{
    display: flex;
    flex-direction: column;
}

.TextHeader{
    color: #535358;
}

.TextHeader h1{
    font-size: 2rem;
}

.TextHeader p{
    font-size: 1.4rem;
    white-space: pre-line;
}

.TextHeader a {
    font-size: 0.9rem;
    box-sizing: border-box;
    margin-top: 2rem;
    padding: 0.5rem 1.6rem;
    border-radius: 25px;
    border: 1px solid #535358;
    background-color: white;
    color: #535358;
    display: flex;
    justify-content: center;
    align-items: center;
    width: max-content;
}

.TextHeader a:hover {
    cursor: pointer;
}

.TextHeader .TextHeaderIcon {
    fill: #535358;
    margin-right: 0.3rem;
    vertical-align: top;
}

.TextHeaderDissolve {
    overflow: hidden;
    height: 0;
    opacity: 0;
    transition:  opacity 200ms, height 0ms;
}

.TextHeaderDisappear {
    display: none;
}

.SearchResultHeaderHolder .CommercialImage {
    display: flex;
    flex-direction: column;
    height: 100%;
    /*max-width: 480px;*/
    cursor: pointer;
}