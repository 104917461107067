.HeaderWithImage {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    max-width: 100vw;
    position: relative;
}

.MobileHeaderImage {
    /*height: 42vh;*/
    max-width: inherit;
}

.Children {
    /*position: absolute;*/
    width: 90vw;
}

.Image {
    height: inherit;
    max-width: inherit;
}

