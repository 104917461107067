.SearchItemMainPage {
    display: flex;
    flex-direction: column;
    height: auto;
    justify-content: left;
    padding-bottom: 0.313rem;
    padding-top: 0.313rem;
}

.SearchItemAppPage {

}

.SearchItemMainPage a:hover {
    background-color: #dfdfdf;
}
.Selected {
    background-color: #E2E8EC;
    width: 100%;
}

.SearchItemMainPage a.span{
    font-weight: bold;
    color: inherit;
    font-family: inherit;
    font-size: 1rem;
    line-height: 2;
    white-space: nowrap;
    cursor: pointer;
    text-align: left;
}

.SearchItemMainPage strong{
    font-weight: bold;
    /*color: orangered;*/
    /*=== nova boja trazenog stringa ===*/
    /*color: #F58300;*/
    color: #219EBC;
    /*color: #FFAE00;*/
}

.SearchItemMainPage a {
    font-size: 0.875rem;
    color: #333333;
    line-height: 1.5;
    outline: none;
    overflow: hidden;
    padding-bottom: 0.5rem;
    padding-inline-end: 1rem;
    /*padding-inline-start: 3.125rem;*/
    padding-top: 0.5rem;
    position: relative;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: normal;
    text-align: left;
}

.SearchItemMainPage:focus {
    background-color: #61dafb;
}

.SearchItemMainPage .SearchItemImage img {
    border-radius: 0.5rem;
    max-height: 3.25rem;
    max-width: 3.25rem;
    padding-left: 0.938rem;
}
.SearchItemMainPage .SearchItemImage{
    border-radius: 0.5rem;
    bottom: 0;
    display: flex;
    height: 2rem;
    justify-content: center;
    align-items: center;
    /*left: 0.75rem;*/
    margin: auto;
    position: absolute;
    top: 0;
    width: 1rem;
    /*===== prebacena lupica na desnu stranu ======*/
    /*right: 0.05rem;*/
    right: .15rem;
}

.ShoppingCartFromShopSearchBar:hover {
    cursor: pointer;
}

@media screen and (min-width: 700px) {

    .SearchItemMainPage .SearchItemImage{
        width: 1.5rem;
        padding-right: 1rem;
    }

    .SearchItemMainPage a {
        /*padding-inline-start: 4rem;*/
        white-space: nowrap;
        /*===== deo (ispod) dodat zbog horizontalnog scroll-a teksta, tj. duzih naziva artikala ======*/
        padding-inline-start: 2rem;
        background-clip: padding-box;
    }

    /*-------- deo (ispod) dodat zbog horizontalnog scroll-a teksta, tj. duzih naziva artikala --------*/
    .ScrollLongArticleName {
        overflow: visible;

        width: 150%;
        cursor: pointer;
        animation: horizontalScroll 5s ease-in-out 1;
        background: #dfdfdf;
    }

    .ScrollLongArticleNameHover {
        overflow: visible;
    }
    .ScrollLongArticleNameHover:hover {
        overflow: visible;
        width: 150%;
        cursor: pointer;
        animation: horizontalScroll 5s ease-in-out 1;
        background: #dfdfdf;
    }

    @keyframes horizontalScroll {
        0% {
            background: #dfdfdf;
        }
        90% {
            transform: translateX(calc(-100% + 85%));
        }
        100% {
            transform: translateX(0);
        }
    }

    .ShortArticleName {
        overflow: visible;
        width: 150%;
    }
    .ShortArticleName:hover {
        overflow: visible;
        width: 150%;
        cursor: pointer;
    }
    /*-------- deo (iznad) dodat zbog horizontalnog scroll-a teksta, tj. duzih naziva artikala --------*/
}