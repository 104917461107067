/*
LOGO BOJE
narandzasta: f39200
braon: a48a7a
crvena: e63133
zelena: 94c11f
plava: 416cab

*/

.MainPageButtonsWrapper {
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 110px;

}

.MainPageButtonsDropdown {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 6px;
}

.MainPageButtonsSelectionBox {
    width: 100%;
    height: 2.52rem;
    background-color: white;
    border-radius: 6px 0 0 6px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    position: relative;
    cursor: pointer;
    border: 1px solid #e6e6e6;
    border-right: none;
}

.DropdownChevron {
    position: absolute;
    bottom: 14px;
    right: 6px;
    width: .75rem;
    stroke: #4D5055;
}

.MainPageSingleButtonWrapper {

}

.MainPageButtonsDropdownClosed {
    visibility: hidden;
}

.MainPageButtonsDropdownOpen {
    visibility: visible;
}

.MainPageButtonsText {
    padding-left: 0;
    font-size: 13px;
}

@media screen and (min-width: 700px) {

    .MainPageButtonsWrapper {
        z-index: 2;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        width: 160px;
        margin-right: 10px;
    }

    .MainPageButtonsSelectionBox {
        height: 3rem;
        background-color: #00B1B0;
        border-radius: 5px;
        border: none;
    }

    .MainPageButtonsText {
        padding-left: 8px;
        font-size: 16px;
    }

    .DropdownChevron {
        width: 1rem;
        stroke: white;
    }
}