.TherapySearchBar {
    width: 100%;
    height: 3rem;
    border: 1px solid #e6e6e6;
    padding-left: 1rem;
    box-sizing: border-box;
}

.TherapySearchBar:hover,
.TherapySearchBar:active {
    border: 1px solid #00B2B1;
}

