.MasterArtikalForm {
    margin: 1.25rem auto;
    width: 80%;
    text-align: center;
    box-shadow: 0 0.125rem 0.188rem #ccc;
    border: 0.125rem solid #eee;
    padding: 0.625rem;
    box-sizing: border-box;
}
.ComboBoxOption{
    padding: 5px;
}

.Selected {
    border-color: #416CAA;

}

.MasterArtikalForm div {
    display: flex;
    align-items: baseline;
}

.MasterArtikalForm p {
    width: 12%;
    color: #888888;
    text-align: left;
}

.MasterArtikalForm input {
    width: 85%;
}

.MasterArtikalForm textarea {
    width: 85%;
}

