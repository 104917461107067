.ModalWithHeader {
    box-sizing: border-box;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    height: 540px;
    width: 440px;
    background-color: white;
    z-index: 35;
    position: fixed;
    left: 5vw;
    top: 15%;
    box-shadow: 0 0 1.2rem #acaaaa;
    max-width: 90vw;
}

@media screen and (min-width: 700px){
    .ModalWithHeader {
        top: 200px;
        left: calc(50% - 220px);
    }
}

.ModalWithHeader .Header {
    width: 100%;
    height: 60px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 10px;
    box-sizing: border-box;
    background-color: #00B2B1;
}

/*.ModalWithHeader .CloseButton {*/
/*    height: 32px;*/
/*    width: 32px;*/
/*    border: 1px solid white;*/
/*    border-radius: 50%;*/
/*    box-sizing: border-box;*/
/*    position: absolute;*/
/*    top: 1rem;*/
/*    right: 1rem;*/
/*    margin: 0;*/
/*    padding: 0;*/
/*    background-color: #00B2B1;*/
/*    display: flex;*/
/*    cursor: pointer;*/
/*}*/

.ModalWithHeader .CloseIcon {
    width: 32px;
    height: 32px;
    /*margin: auto;*/
    color: white;
    cursor: pointer;
}

/*.ModalWithHeader .HalfCircle {*/
/*    width: 120px;*/
/*    height: 60px;*/
/*    background-color: transparent;*/
/*    border-top-left-radius: 100px;*/
/*    border-top-right-radius: 100px;*/
/*    border: 3px solid #00B2B1;*/
/*    border-bottom: 0;*/
/*    margin: -60px auto 0;*/
/*    position: relative;*/

/*    -webkit-box-sizing: border-box;*/
/*    -moz-box-sizing: border-box;*/
/*    box-sizing: border-box;*/
/*}*/

/*.ModalWithHeader .Circle {*/
/*    height: 100px;*/
/*    width: 100px;*/
/*    border-radius: 50%;*/
/*    background-color: white;*/
/*    margin: 7px auto;*/
/*    border: 1px solid #036e6e;*/
/*    display: flex;*/
/*    align-items: center;*/
/*    justify-content: center;*/
/*    color: #00B2B1;*/
/*}*/

.ModalWithHeader .Content {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding: 2rem;
    height: calc(100% - 60px);
    /*margin: auto;*/
    justify-content: flex-end;
    color: #5b6977ff;
}

.ModalWithHeader .ActionWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: auto;
    width: 100%;
}