/*main {*/
/*    width: 70%;*/
/*    margin: auto;*/
/*    text-align: center;*/
/*}*/

.Zoom {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 65px;
}

.Zoom h1 {
    text-align: center;
}

.Zoom .Description {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;
}

#meetingSDKElement {
    top: 59px !important;
    left: 0 !important;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 244px; /* width of speaker and ribbon view */
}

.MuiBox-root {
    width: 700px;
}