.ArticleCategories {
    width: 340px;
    border: 1px solid #00B2B1;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 144px;
    top: 8px;
    z-index: 31;
    background-color: white;
    font-size: 14px;
}

.ArticleSubcategories1 {
    position: absolute;
    top: 8px;
    left: 483px;
    width: 340px;
    border: 1px solid #00B2B1;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    z-index: 31;
    background-color: white;
    font-size: 14px;
}

.ArticleSubcategories2 {
    position: absolute;
    top: 8px;
    left: 822px;
    min-width: 340px;
    height: 560px;
    border: 1px solid #00B2B1;
    background-color: white;
    z-index: 31;
    font-size: 14px;
}