.WrapperForOCPT {
    width: 1304px;
    min-height: 60vh;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    flex-direction: column;
    background-color: white;
    box-sizing: border-box;
    border-radius: 6px;
    color: #5b6977ff;
}

.WrapperForOCPTLeftSide {
    min-height: min-content;

}

.WrapperForOCPTRightSide {
    width: 100%;
    min-height: 60vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /*border-radius: 0.375rem;*/
    box-sizing: border-box;
}

.WrapperForOCPTTop {
    background-color: white;
}