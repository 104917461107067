.ArticleCategoriesRes {
    position: fixed;
    top: 44px;
    left: 0;
    height: calc(100vh - 44px);
    width: 100%;
    z-index: 50;
    background-color: white;
}

.CategoryHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    padding: 0 8px 0 16px;
    border-bottom: 1px solid lightgray;
}

.Selected {
    background-color: #00B2B1;
    color: white;
}