.SearchBarMainPageWrapper {
    display: flex;
    position: relative;
    border-radius: 3px;
    z-index: 23;
    margin: 0; /* kada se vrate linkovi iznad search bara, vratiti na: margin: 0 auto*/
    width: 90vw;
}

.SearchBarActionMenuWrapper {
    display: flex;
    position: relative;
    border-radius: 3px;
    width: 100%;
    z-index: 23;
}

.SearchBarMainPage {
    display: flex;
    height: 2.52rem;
    width: 100%;
    position: relative;
}

.SearchIcon {
    position: absolute;
    height: 24px;
    width: 24px;
    top: 13px;
    right: 1rem;
    color: #535358;
    z-index: 23;
}

.SearchIconOnActionMenu {
    position: absolute;
    height: 22px;
    width: 22px;
    top: 0.438rem;
    right: 1rem;
    color: #535358;
    z-index: 23;
}

.SearchBarActionMenu {
    height: 2.25rem;
    /*flex: 1;*/
    width: 100%;
    display: flex;
}

.SearchBarMainPage input {
    height: auto;
    width: 100%;
    position: relative;
    border-radius: 3px;
    border: 1px solid #e6e6e6;
    padding-left: 2rem;
    outline: 0;
    box-shadow: 0 0 0 0 rgba(136, 136, 136, 0);
    /*transition: box-shadow .3s;*/
    font-size: 1rem;
    color: #535358;
    z-index: 22;
    background-color: white;
}

.SearchBarActionMenu input {
    font-size: 1rem;
}

.SearchBarActionMenu input:focus, input:hover{
    border: 1px solid #013a63ff;
}

.SearchBarMainPage ::placeholder{
    color: #8b8989;
    opacity: 0.5;
}

.SearchBarActionMenu ::placeholder {
    color: #8b8989;
    opacity: 1;
    font-size: 1rem;
}

.SearchBarAppPage input:focus{
    /*padding: 0 50px;*/
}

.SearchBarInactive {
    box-shadow: none;
    border: none;
    border-radius: 0;
}

.SearchBarMainPage input:focus {
    /*box-shadow: 0 0 0.625rem -0.25rem #535358;*/
    /*border: 1px solid #535358;*/
}

.SearchBarHidden {
    display: none;
}

@media only screen and (min-width: 700px) {
    .SearchBarMainPageWrapper {
        display: flex;
        position: relative;
        border-radius: 3px;
        z-index: 23;
        margin: 0 auto 10rem; /* kada se vrate linkovi iznad search bara, vratiti na: margin: 0 auto*/
        width: 100%;
        max-width: 60rem; /* stari sajt 650px*/
        /*text-align: center;*/
    }

    .SearchBarActionMenuWrapper {
        width: 30.5rem; /* 592px */
        z-index: 22;
    }

    .SearchBarMainPage {
        height: 3rem;
    }

    .SearchBarActionMenu {
        height: 44px;
    }
}

@media screen and (max-width: 699px) {
    .SearchBarMainPage input {
        padding-left: .5rem;
    }

    .SearchBarMainPageWrapper:focus-within {
        position: absolute !important;
    }
}

@media (min-width: 1640px) {
    .SearchBarActionMenuWrapper {
        width: 40.5rem; /* 592px */
    }
}

@media (min-width: 1900px) {
    .SearchBarActionMenuWrapper {
        width: 40.5rem; /* 592px */
    }
}

.SearchBarActionMenu .ShopName {
    background-color: #416CAA;
    font-size: 1rem;
    max-width: 30%;
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    border-radius: 20px 0 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.SearchBarShopPage {
    width: 100%;
    border: none;
}

/* placeholder u search baru */
.SearchBarActionMenu .SearchBarShopPage {
    padding-left: 2rem;
}

[type=input] {
    background-image: url("../../../../assets/icons/search_icon.svg");
    background-position: calc(100% - 16px)  center;
    background-repeat: no-repeat;
    background-size: 16px 16px;
    padding: 1em 16px 1em 1em;
    border: 1px solid #8b8989;
}

[type=input]:not(:placeholder-shown) {
    background-image: none;
}