.HeaderImage {
    background-repeat: no-repeat;
    background-size: 100vw;
    background-position-y: -60px;
    height: 185px;
}

.TextTitle {
    margin: 2rem;
    font-size: 22px;
    text-align: center;
}

.Text {
    width: 90vw;
    margin: auto;
    padding: 0 1rem;
    color: #474A53;
}

.TextWithBackground {
    background-color: #E2E8EC;
    display: flex;
    flex-direction: column;
    margin: 3rem 0;
    color: #474A53;
}

.TableStyle {
    border-radius: 16px;
    border: 1px solid #FFAE00;
    width: 90vw;
    margin: 2rem auto;
    text-align: center;
    border-collapse: collapse;
}

.TableStyle .OrangeRow {
    color: white;
    background-color: #FFAE00;
    font-weight: bold;
}

.TableStyle .ColoredText {
    color: #FFAE00;
}

.TableStyle .MiddleColumn {
    border-left: 1px solid #FFAE00;
    border-right: 1px solid #FFAE00;
}

.TableStyle th {
    padding: 8px;
    width: 33%;
}

@media screen and (min-width: 700px) {
    .HeaderImage {
        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position-y: -200px;
        height: 600px;
    }

    .Text {
        width: 820px;
        border-left: 2px solid #FFAE00;
    }

    .TextWithBackground {
        display: flex;
        flex-direction: row;
        margin: 3rem 0;
    }

    .TableStyle {
        width: 854px;
    }
}