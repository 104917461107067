.NoMatchSearchItemMainPage {
    display: flex;
    flex-direction: column;
    height: auto;
    justify-content: left;
    padding-bottom: 0.313rem;
    padding-top: 0.313rem;
}

.NoMatchSearchItemAppPage {

}

.NoMatchSearchItemMainPage a:hover {
    background-color: #dfdfdf;
}

.NoMatchSearchItemMainPage a.span{
    font-weight: bold;
    color: inherit;
    font-family: inherit;
    font-size: 1rem;
    line-height: 2;
    white-space: nowrap;
    cursor: pointer;
    text-align: left;
}

.NoMatchSearchItemMainPage a {
    font-size: 0.875rem;
    color: #333333;
    line-height: 1.5;
    outline: none;
    overflow: hidden;
    padding-bottom: 0.5rem;
    padding-inline-end: 1rem;
    padding-inline-start: 4rem;
    padding-top: 0.5rem;
    position: relative;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;

}
.NoMatchSearchItemMainPage:focus {
    background-color: #61dafb;
}

.NoMatchSearchItemMainPage .NoMatchSearchItemImage img {
    border-radius: 0.5rem;
    max-height: 3.25rem;
    max-width: 3.25rem;
    padding-left: 0.938rem;
}
.NoMatchSearchItemMainPage .NoMatchSearchItemImage{
    align-items: center;
    border-radius: 0.5rem;
    bottom: 0;
    display: flex;
    height: 2rem;
    justify-content: center;
    left: 0.75rem;
    margin: auto;
    position: absolute;
    top: 0;
    width: 1.5rem;
}
