.CMSPage {
    display: flex;
    padding-top: 100px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

/*.CMSButton {*/
/*    background-color: white;*/
/*    color: #4a76af;*/
/*    border: 1px solid #4a76af;*/
/*    border-radius: 8px;*/
/*    padding: 8px 14px;*/
/*}*/

/*.CMSButton:hover {*/
/*    background-color: #4a76af;*/
/*    color: white;*/
/*    border: 1px solid #4a76af;*/
/*}*/

/*.CMSButton:focus {*/
/*    background-color: #4a76af;*/
/*    color: white;*/
/*    border: 1px solid #4a76af;*/
/*}*/

.ErrorMsg {
    color: #721c24 !important;
    background-color: #f8d7da;
    border-color: #f5c6cb;
    padding: 0.75rem;
    margin: auto;
}

.SuccessMsg {
    color: #155724 !important;
    background-color: #d4edda;
    border-color: #c3e6cb;
    border-radius: 5px;
    padding: 0.75rem;
    margin: 1rem auto;
}

.ActionButtonsHolder {
    display: flex;
    flex-direction: column;
    align-items: center;
}
