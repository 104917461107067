.LBOInputField {
    border: 1px solid #e2e8ecff;
    font-size: 18px;
    border-radius: 6px !important;
    height: 2.375rem !important;
    width: 206px !important;
    padding-left: 16px;
}

.LBOInputField:hover {
    border: 1px solid #00B2B1;
}

.LBOInputField:focus {
    border: 1px solid #00B2B1;
}

.LBOLabel {
    margin: 0.5rem 0 !important;
    color: #535358;
    font-size: 14px;
}

@media screen and (max-width: 699px) {
    .LBOLabel {
        margin: 0.5rem 0 !important;
    }
}

.ErrorMessage {
    color: red;
    font-size: 12px;
    width: 90%;
    margin-top: 0.2rem;
    height: 12px;
}

.LBOInputFieldWrapper {
    display: flex;
    flex-direction: column;
    width:100%;
}

.SaveLBOCheckbox {
    opacity: 1 !important;
    border: 1px solid grey;
    position: relative !important;
    height: auto !important;
    width: auto !important;
}
