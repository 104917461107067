.AddressesList {
    /*box-shadow: 0 0 0.8rem #cccccc;*/
    border: 1px solid #e2e8ecff;
    border-radius: 5px;
    height: 6.75rem;
    padding: 1rem 1.5rem;
    min-width: 15rem;
    width: 17.0625rem;
    margin-top: 0.75rem;
    cursor: pointer;
    position: relative;
    box-sizing: border-box;
    margin-right: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.UserAddressesWrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.AddressesListRadioButtonWrapper {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border: 1px solid #5b6977ff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.AddressesListRadioButton {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: 1px solid #5b6977ff;
}

.AddressesListRadioButtonWrapperSelected {
    border: 1px solid #00B2B1;
}

.AddressesListRadioButtonSelected {
    background-color: #00B2B1;
    border: 1px solid #00B2B1;
}

.ShopInfo {
    background-color: #416caa;
    color: white;
    border: 1px solid #416caa;
    border-radius: 15px;
    padding: 1rem;
}

@media screen and (max-width: 699px) {
    .AddressesList {
        width: 90%;
    }

    .UserAddressesWrapper {
        width: 100vw;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }
}