.MainPageSubtitleWrapper {
    /*font-family: "Montserrat", sans-serif;*/
    color: black;
    display: flex;
    flex-flow: column;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: 126px;
    padding: 3% 3% 3% 3%;
    box-sizing: border-box;
}

.MainPageSubtitleWrapper .Big {
    font-weight: bold;
    box-sizing: border-box;
    text-align: left;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    font-size: 24px;
    margin: 0;
    padding: .5rem .5rem;
}

.AnimatedText {
    min-height: 60px;
    margin: -20px 0 0 -9px;
    font-size: 1rem;
    display: flex;
    align-items: center;
    font-weight: bolder;
    padding: 0.5rem 1rem 0 0.5rem;
}
@media screen and (min-width: 700px) {

    .MainPageSubtitleWrapper {
        height: 126px;
        width: 500px;
        padding: 0;
    }

    .MainPageSubtitleWrapper .Big {
        height: 126px;
        font-size: 3rem;
        padding: 0 0 70px 0;
    }

    .AnimatedText {
        margin-top: -50px;
        font-size: 1.5rem;
        padding: 0;
        width: max-content;
    }
}