.SteplineWrapper {
    width: 1366px;
    box-sizing: border-box;
    padding: 1.5rem 32px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.StepLineInfoText {
    width: 320px;
    height: 162px;
    color: #5b6977ff;
    font-size: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    }

.StepsWrapper {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.StepNumberWrapper {
    width: 90%;
    height: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.SteplineItem {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin: 10px;
    z-index: 10;
}

.SteplineItem.Unmarked,
.SteplineItem .Skipped {
    border-bottom: 2px solid #bdccd4ff;
}

.SteplineItem .Marked {
    border-bottom: 2px solid #00B2B1;
}

.StepNumberWrapper.Unmarked,
.StepNumberWrapper .Skipped {
    background-color: #bdccd4ff;
    color: #013a63ff;
    border: 1px solid #bdccd4ff;
}

.StepNumberWrapper.Marked{
    background-color: #00B2B1;
    color: white;
    border: 1px solid #00B2B1;
}

.StepNumberText {
    font-size: 20px;
    width: 100%;
    font-weight: bold;
}

.StepNumberText.Unmarked,
.StepNumberText.Skipped {
    color: #bdccd4ff;
}

.StepNumberText.Marked {
    color: #00B2B1;
}
