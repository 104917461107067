.SelectHolder {
    display: flex;
    align-items: center;
    position: relative;
    border: none;
    padding-right: 0.75rem;
}

select {
    height: 100%;
    width: 135px;
    font-size: 14px;
    color: #88959eff;
    border-radius: 3px;
    cursor: pointer;
    border: none;
}


@media screen and (min-width: 700px) {
    .SelectHolder {
        border: 1px solid #e6e6e6;
    }
    select {
        width: 180px;
        height: 44px;
        padding: 0 12px;
    }
}