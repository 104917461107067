.NewTherapyHeader {
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    align-items: center;
    font-weight: bold;
    margin-bottom: 32px;
    font-size: 18px;
}

.NewTherapyHeaderActions {
    display: flex;
}

.NewTherapyContent {
    display: flex;
}

.ContentLeftSide {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin-right: 2rem;
}

.ContentRightSide {
    display: flex;
    flex-direction: column;
    width: 50%;
    padding-left: 32px;
    box-sizing: border-box;
    border-left: 1px solid #E2E8EC;
}

.TherapyName {
    height: 3rem;
    width: 100%;
    box-sizing: border-box;
    padding: 1px 2px 1px 16px !important;
    border: 1px solid #e6e6e6;
    border-radius: 6px;
    border-bottom: 1px solid #E2E6EA;
}

.TherapyName:hover,
.TherapyName:focus,
.TherapyName:active {
    border: 1px solid #00B2B1;
}

.NewTherapyMobileWrapper {
    display: flex;
    flex-direction: column;
    color: #4a4f54ff;
    width: 90vw;
    margin: 1rem auto;
    position: relative;
    height: calc(100% - 64px);
    /*justify-content: space-between;*/
}

.TherapyNameMobile {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.NewTherapyArticlesMobile {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}

