.Input {
    height: 30px;
    width:80%;
    border-bottom: 1px solid grey;
    border-top: none;
    border-left: none;
    border-right: none;
    padding-left: 1rem;
}

.Input:hover {
    border-top: none;
    border-left: none;
    border-right: none;
    padding-left: 1rem;
}