.Input {
    /*width: 100%;*/
    padding: 0.45rem;
    box-sizing: border-box;
}

.Label {
    /*font-weight: bold;*/
    display: block;
    margin-bottom: 0.5rem;
}

.InputElement {
    outline: none;
    border: 0.063rem solid #ccc;
    background-color: white;
    font: inherit;
    padding: 0.375rem 0.625rem;
    display: block;
    width: 100%;
    box-sizing: border-box;
}

.InputElement:focus {
    outline: none;
    background-color: #ccc;
}
