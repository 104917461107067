.ContentWrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
}

.ContentWrapper .Title {
    margin: 0;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
}

.ContentWrapper .Text {
    text-align: center;
    font-size: 14px;
    line-height: 1.7;
}
