.MyProfilePaneHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 5rem;
}

.MyProfileInformation {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin-right: 40px;
}

.MyProfileInformation h2 {
    font-weight: 500;
}

.MyProfileInformation .ProfileTitle {
    display: flex;
    align-items: center;
}

.MyProfileInformation .UserInformation {
    background-color: #f1f4f6ff;
    padding: 48px;
    margin-top: 1rem;
    border-radius: 6px;
}

.MyProfileInformation .DeleteProfile {
    color: #535358;
    margin-top: 3rem;
}

.MyProfilePaneHeaderMobile .DeleteProfile, .DeleteProfile h2, .DeleteProfile p {
    display: block;
    color: #535358;
}

.MyAddresses {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin-left: 40px;
}

.MyProfilePaneHeaderMobile {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.MyProfilePaneHeaderUserName {
    display: flex;
    justify-content: center;
    align-items: center;
}