.HeaderText {
    margin: 0;
    font-size: 24px;
}

.ArticleInfoWrapper {
    border-top: 1px solid #E2E8EC;
    border-bottom: 1px solid #E2E8EC;
    margin-top: 1rem;
}

.ArticleInfoWrapper .Name {
    font-weight: 600;
}

.Message {
    font-weight: 600;
    font-size: 18px;
}

.BottomActionsWrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
}