.FooterMobile {
    font-size: 0.75rem;
    color: #535358;
    padding: .7rem;
    text-align: center;
    position: relative;
    bottom: 0;
    position: relative !important;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
}

.Footer {
    font-size: 0.75rem;
    color: #4F4F4F;
    padding: 1.25rem;
    /*width: 100vw;*/
    bottom: 0;
    /*box-shadow: 0 0 0.8rem #cccccc;*/
    position: relative !important;
    /*position: absolute;*/
    text-align: center;

    box-sizing: border-box;
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: white;
}

/*.Footer div {*/
/*    !*margin: auto;*!*/
/*    position: absolute;*/
/*    bottom: 0;*/
/*    !*width: 100%;*!*/
/*    !*height: 2.5rem;*!*/
/*}*/




/*.FooterWrapper {*/
/*    width: 1302px;*/
/*    display: flex;*/
/*    box-sizing: border-box;*/
/*    padding: 3rem 4rem 3rem 2rem;*/
/*}*/
.Footer_Top {
    /*width: 1302px;*/
    display: flex;
    box-sizing: border-box;
    font-size: 16px;
    /*padding: 3rem 4rem 3rem 2rem;*/
    /*padding: 32px 32px 32px 32px;*/
}

.Footer_LeftBox {
    color: #4a4f54;
    width: 30%;
    /*padding: 2rem 4rem;*/
    padding: 1rem 0 2rem 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.Footer_LeftBox p {
    margin: 0;
    padding-top: 0.4rem;
}

.Footer_RightBox {
    width: 70%;
    padding: 2rem;
    display: flex;
    /*justify-content: space-around;*/

    justify-content: flex-end;
}

.Footer_RightBox_Links {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: #4a4f54;

    padding-right: 8rem;
}
.Footer_RightBox_Links a {
    padding-top: 0.4rem;
    color: inherit;
}

.Footer_Bottom {
    color: #4a4f54;
    height: content-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    padding: 0 0.5rem;
}

.Footer_Bottom_SocialNetworks {
    font-size: 1.4rem;
    padding: 6px;
    margin: 0.5rem;
    border: 1px solid transparent;
    border-radius: 20%;
    color: black;
    width: 32px;
    height: 32px;
}

.Footer_Bottom_SocialNetworks:hover {
    /*stroke: white;*/
    /*fill: #00B2B1;*/
    border: 1px solid #00B2B1;
}

.FooterSectionTitle {
    font-weight: bold;
}
