.Modal {
    position: fixed;
    z-index: 500;
    background-color: white;
    /*width: 70%;*/
    border: 1px solid #e2e8ecff;
    padding: 16px;
    left: 15%;
    box-sizing: border-box;
    transition: all 0.3s ease-out;
    border-radius: 6px;
}

@media screen and (min-width: 700px){
    .Modal {
        padding: 0;
        /*top: 30%;*/
    }

    .ModalHeader {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        background-color: #00B1B0;
        height: 60px;
        padding-right: 1rem;
    }

}