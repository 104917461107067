.ArticleSubcategories2 {
    display: flex;
    align-items: center;
    height: 32px;
    padding: 0 16px;
    box-sizing: border-box;
    cursor: pointer;
}

.ArticleSubcategories2:hover {
    color: #00B1B0;
}