.OrderStatus {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.Status {
    font-weight: bold;
}

.Message {
    text-align: center;
    font-size: 12px;
}

@media screen and (min-width: 700px){
    .Status {
        font-size: 18px;
        justify-content: center;
    }

    .Message {
        font-size: 16px;
    }
}

.ColoredStatusMobile {
    display: flex;
    align-items: center;
}