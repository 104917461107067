/*!*.Loader {*!*/
/*!*    font-size: 0.625rem;*!*/
/*!*    margin: 3.125rem auto;*!*/
/*!*    text-indent: -9999rem;*!*/
/*!*    width: 11rem;*!*/
/*!*    height: 11rem;*!*/
/*!*    border-radius: 50%;*!*/
/*!*    background: #e0e2e4;*!*/
/*!*    background: -moz-linear-gradient(left, #e0e2e4 10%, rgba(224,226,228, 0) 42%);*!*/
/*!*    background: -webkit-linear-gradient(left, #e0e2e4 10%, rgba(224,226,228, 0) 42%);*!*/
/*!*    background: -o-linear-gradient(left, #e0e2e4 10%, rgba(224,226,228, 0) 42%);*!*/
/*!*    background: -ms-linear-gradient(left, #e0e2e4 10%, rgba(224,226,228, 0) 42%);*!*/
/*!*    background: linear-gradient(to right, #e0e2e4 10%, rgba(224,226,228, 0) 42%);*!*/
/*!*    position: relative;*!*/
/*!*    -webkit-animation: load3 1.4s infinite linear;*!*/
/*!*    animation: load3 1.4s infinite linear;*!*/
/*!*    -webkit-transform: translateZ(0);*!*/
/*!*    -ms-transform: translateZ(0);*!*/
/*!*    transform: translateZ(0);*!*/
/*!*}*!*/
/*!*.Loader:before {*!*/
/*!*    width: 50%;*!*/
/*!*    height: 50%;*!*/
/*!*    background: #e0e2e4;*!*/
/*!*    border-radius: 100% 0 0 0;*!*/
/*!*    position: absolute;*!*/
/*!*    top: 0;*!*/
/*!*    left: 0;*!*/
/*!*    content: '';*!*/
/*!*}*!*/
/*!*.Loader:after {*!*/
/*!*    background: white;*!*/
/*!*    width: 75%;*!*/
/*!*    height: 75%;*!*/
/*!*    border-radius: 50%;*!*/
/*!*    content: '';*!*/
/*!*    margin: auto;*!*/
/*!*    position: absolute;*!*/
/*!*    top: 0;*!*/
/*!*    left: 0;*!*/
/*!*    bottom: 0;*!*/
/*!*    right: 0;*!*/
/*!*}*!*/
/*!*@-webkit-keyframes load3 {*!*/
/*!*    0% {*!*/
/*!*        -webkit-transform: rotate(0deg);*!*/
/*!*        transform: rotate(0deg);*!*/
/*!*    }*!*/
/*!*    100% {*!*/
/*!*        -webkit-transform: rotate(360deg);*!*/
/*!*        transform: rotate(360deg);*!*/
/*!*    }*!*/
/*!*}*!*/
/*!*@keyframes load3 {*!*/
/*!*    0% {*!*/
/*!*        -webkit-transform: rotate(0deg);*!*/
/*!*        transform: rotate(0deg);*!*/
/*!*    }*!*/
/*!*    100% {*!*/
/*!*        -webkit-transform: rotate(360deg);*!*/
/*!*        transform: rotate(360deg);*!*/
/*!*    }*!*/
/*!*}*!*/

/*.LoaderHolder {*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    align-items: center;*/
/*    padding-top: 1.25rem;*/
/*}*/

/*.Loader {*/
/*    animation: rotate 1600ms infinite;*/
/*    height: 50px;*/
/*    width: 50px;*/
/*}*/

/*.LoaderSmall {*/
/*    animation: rotateSmall 1600ms infinite;*/
/*    height: 50px;*/
/*    width: 50px;*/
/*}*/

/*.Loader:before,*/
/*.Loader:after,*/
/*.LoaderSmall:before,*/
/*.LoaderSmall:after {*/
/*    border-radius: 50%;*/
/*    content: '';*/
/*    display: block;*/
/*    height: 20px;*/
/*    width: 20px;*/
/*}*/

/*.Loader:before,*/
/*.LoaderSmall:before {*/
/*    animation: ball1 1s infinite;*/
/*    background-color: #E63133;*/
/*    box-shadow: 30px 0 0 #F09200;*/
/*    margin-bottom: 10px;*/
/*}*/

/*.Loader:after,*/
/*.LoaderSmall:after {*/
/*    animation: ball2 1s infinite;*/
/*    background-color: #416caa;*/
/*    box-shadow: 30px 0 0 #94C11F;*/
/*}*/

/*@keyframes rotate {*/
/*    0% {*/
/*        -webkit-transform: rotate(0deg) scale(1.8);*/
/*        -moz-transform: rotate(0deg) scale(1.8);*/
/*    }*/
/*    50% {*/
/*        -webkit-transform: rotate(360deg) scale(2.2);*/
/*        -moz-transform: rotate(360deg) scale(2.2);*/
/*    }*/
/*    100% {*/
/*        -webkit-transform: rotate(720deg) scale(1.8);*/
/*        -moz-transform: rotate(720deg) scale(1.8);*/
/*    }*/
/*}*/

/*@keyframes rotateSmall {*/
/*    0% {*/
/*        -webkit-transform: rotate(0deg) scale(1);*/
/*        -moz-transform: rotate(0deg) scale(1);*/
/*    }*/
/*    50% {*/
/*        -webkit-transform: rotate(360deg) scale(1.4);*/
/*        -moz-transform: rotate(360deg) scale(1.4);*/
/*    }*/
/*    100% {*/
/*        -webkit-transform: rotate(720deg) scale(1);*/
/*        -moz-transform: rotate(720deg) scale(1);*/
/*    }*/
/*}*/

/*@keyframes ball1 {*/
/*    0% {*/
/*        box-shadow: 30px 0 0 #f8b334;*/
/*    }*/
/*    50% {*/
/*        box-shadow: 0 0 0 #f8b334;*/
/*        margin-bottom: 0;*/
/*        -webkit-transform: translate(15px, 15px);*/
/*        -moz-transform: translate(15px, 15px);*/
/*    }*/
/*    100% {*/
/*        box-shadow: 30px 0 0 #f8b334;*/
/*        margin-bottom: 10px;*/
/*    }*/
/*}*/

/*@keyframes ball2 {*/
/*    0% {*/
/*        box-shadow: 30px 0 0 #97bf0d;*/
/*    }*/
/*    50% {*/
/*        box-shadow: 0 0 0 #97bf0d;*/
/*        margin-top: -20px;*/
/*        -webkit-transform: translate(15px, 15px);*/
/*        -moz-transform: translate(15px, 15px);*/
/*    }*/
/*    100% {*/
/*        box-shadow: 30px 0 0 #97bf0d;*/
/*        margin-top: 0;*/
/*    }*/
/*}*/


/*loader three dots*/
.Loader,
.Loader:before,
.Loader:after {
    border-radius: 50%;
    width: 2.5em;
    height: 2.5em;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation: load7 1.8s infinite ease-in-out;
    animation: load7 1.8s infinite ease-in-out;
    color: #00B2B1;
}
.Loader {
    color: #00B2B1;
    font-size: 8px;
    margin: 30px auto;
    position: relative;
    text-indent: -9999em;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
    top: -20px;
}
.Loader:before,
.Loader:after {
    content: '';
    position: absolute;
    top: 0;
}
.Loader:before {
    left: -3.5em;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}
.Loader:after {
    left: 3.5em;
}
@-webkit-keyframes load7 {
    0%,
    80%,
    100% {
        box-shadow: 0 2.5em 0 -1.3em;
    }
    40% {
        box-shadow: 0 2.5em 0 0;
    }
}
@keyframes load7 {
    0%,
    80%,
    100% {
        box-shadow: 0 2.5em 0 -1.3em;
    }
    40% {
        box-shadow: 0 2.5em 0 0;
    }
}
/*end loader three dots*/