.ShopInfo {
    background-color: #416caa;
    color: white;
    border: 1px solid #416caa;
    border-radius: 15px;
    padding: 1rem;
}

.DeliveryChoiceButton {
    border: 1px solid #00B2B1;
    border-radius: 3px;
    box-sizing: border-box;
    cursor: pointer;
    width: 320px;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    color: #00B2B1;
    stroke: #00B2B1;
}

.DeliveryChoiceButton:hover {
    background-color: #16CBCA;
    color: white;
    stroke: white;
}

.DeliverySelected {
    background-color: #00B2B1;
    color: white;
    stroke: white;
}

.DeliveryChoiceButton h4 {
    width: max-content;
}

.DeliveryChoiceButtonHolder {
    height: 40px;
    border: 1px solid transparent;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
}

.DeliveryChoiceButtonDisabled {
    border: 1px solid #00B2B1;
    border-radius: 3px;
    box-sizing: border-box;
    cursor: pointer;
    width: 320px;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    color: #00B2B1;
    stroke: #00B2B1;
}

.ChoiceWrapper {
    display: flex;
    margin-top: 1.5rem;
    border-top: 1px solid #e2e8ecff;
    height: 35vh;
    align-items: center;
    justify-content: left;
}

.DeliveryChoiceButtonWrapper {
    height: 50%;
}