.LogInPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 1rem;
}

.LogInPage a{
    color: #416caa;
    cursor: pointer;
    padding-top: 20px;
}

.LogInPage a:hover {
    text-decoration: underline;
}

.LogInPage form {
    padding: 2rem;
}

.ErrorMessage {
    color: red;
}
.Shake {
    animation: shake-animation 4.72s ease infinite;
    transform-origin: 50% 50%;
}

.EyeIcon {
    width: 24px;
    stroke: #4D5055;
    position: absolute;
    top: 38.5%;
    right: -12px;
    cursor: pointer;
}

@keyframes shake-animation {
    0% { transform:translate(0,0) }
    1.78571% { transform:translate(5px,0) }
    3.57143% { transform:translate(0,0) }
    5.35714% { transform:translate(5px,0) }
    7.14286% { transform:translate(0,0) }
    8.92857% { transform:translate(5px,0) }
    10.71429% { transform:translate(0,0) }
    100% { transform:translate(0,0) }
}
