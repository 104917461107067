.PicturePlacer {
    /*background-image: url("../../../assets/Apoteka-cover-1_mobile.jpg");*/
    /*background: linear-gradient(to bottom right, red, yellow), url("../../../assets/Apoteka-cover-1_mobile.jpg");*/
    height: 100vh;
    display: flex;
    flex-direction: column;
    background-position-x: right;
    background-repeat: no-repeat;
    background-size: cover;
    justify-content: space-between;
}

.PicturePlacer .Content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: auto 0;
    position: relative;
}

.PicturePlacer .LeftPane {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-self: flex-end;
    padding-left: 2rem;
    max-width: 445px;
    max-height: 500px;
    margin: auto 0;
}

.PicturePlacer .CommercialImage {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-self: flex-start;
    /*padding-left: 128px;*/
    max-width: 600px;
    /*max-height: 500px;*/
    margin: auto 0;
    position: absolute;
    right: 0;
    bottom: 0;
    justify-content: center;
    padding-right: 40px;
    cursor: pointer;
}

.CommercialImage .Image {
    width: 500px;
    height: 500px;
}

@media screen and (min-width: 700px){
    .PicturePlacer {
        /*background: linear-gradient(to bottom right, white, transparent 35%), url("../../../assets/Apoteka-cover-1.jpg") no-repeat;*/
        /*background: url("../../../assets/Apoteka-cover-1-grad.jpg") no-repeat;*/
        /*background: linear-gradient(to bottom right, white, transparent 25%), url("../../../assets/medicine-4097308_1920.jpg") no-repeat;*/
        background-position-x: center;
        /*background-repeat: no-repeat;*/
        background-size: cover;
        justify-content: flex-start;
        display: flex;
        flex-direction: column;
    }
}