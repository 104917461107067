.ShopInfo {
    background-color: #416caa;
    color: white;
    border: 1px solid #416caa;
    border-radius: 15px;
    padding: 1rem;
}

.PulsingPhoneText {
    /* Chrome, Safari, Opera */
    -webkit-animation: PULSE 1s infinite;

    /* Standard Syntax */
    animation: PULSE 1.25s infinite;
}

/* Chrome, Safari, Opera */
@-webkit-keyframes PULSE{
    0%{color:rgba(255,0,255,0);}
    110%{color: black;}
}

/* Standard Syntax */
@keyframes PULSE{
    0%{color:rgba(0,255,255, 0);}
    110%{color: black;}
}

.UserNoteWrapper {
    margin: 1rem 0 1rem;
    width: 90%;
}

.UserNoteWrapper textarea {
    height: 4.5rem;
    border-radius: 6px;
}

.PrescriptionImagesPreviewPlacer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
}


.TooltipWrapper {
    position: relative;
}

.TooltipText {
    /* Position the tooltip */
    left: -5%;
    top: 4rem;
}

.TooltipText::after {
    /* position tooltip correctly */
    left:50%;

    /* vertically center */
    bottom: 94%;
}

.TooltipWrapper:hover .TooltipText,
.TooltipWrapper:hover .TooltipText:after
{
    visibility: visible;
    opacity: 1;
    transition: 600ms ease-out;
}

.Note {
    width: 100%;
    resize: none;
    border: 1px solid #e5e5e5;
}

.CharactersNumber {
    display: flex;
    justify-content: flex-end;
    font-size: 12px;
    color: #5b6977ff;
}

.PrescriptionPart {
    width: 90%;
    padding-top: 2rem;
    margin: auto;
    border-top: 1px solid #e2e8ecff;
}

.PrescriptionPartCheckbox {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 90%;
    padding-top: 1rem;
    border-top: 1px solid #e2e8ecff;
    margin-top: 1rem;
}

.DeliveryOptionHeaderMobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 1.5rem;
    width: 90%;
    border-bottom: 1px solid #e2e8ecff;
    color: #4D5055;
}

.UserAddressMobile {
    width: 100vw;
    padding: .5rem;
    background-color: #FAFAFB;
    display: flex;
    justify-content: center;
}

@media screen and (min-width: 700px){
    .UserNoteWrapper {
        width: 100%;
    }

    .UserNoteWrapper textarea {
        height: auto;
        border-radius: 6px;
        padding: 1rem;
    }

    .PrescriptionPart {
        width: auto;
        margin: 0;
    }
    
    .PrescriptionPartCheckbox {
        width: 100%;
    }
}