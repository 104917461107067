.StoreItem {
    border: 1px solid #e2e8ecff;
    background-color: white;
    box-sizing: border-box;
}

.StoreItemGridView {
    border-radius: 0.375rem;
    width: 90%;
    height: 230px;
    display: flex;
    /*line-height: 0.8rem;*/
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 1rem;
}

/*.StoreItemListView {*/
/*    width: 70rem;*/
/*    height: 4rem;*/
/*    display: inline-flex;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*    flex-direction: row;*/
/*    !*margin-bottom: 0.8rem;*!*/
/*    padding-left: 1.5rem;*/
/*    padding-right: 0;*/
/*    border-top: 1px solid #e8e7e7;*/
/*    border-bottom: 1px solid #e8e7e7;*/
/*}*/

.StoreItemListView:hover,
.StoreItemGridView:hover {
    transition: 400ms ease-out;
    box-shadow: 0 0 0.75rem #A1A0A0;
    border: 1px solid #a1a0a0;
}

.StoreItemListView.Selected {
    background-color: #f5f5f6;
}


.StoreItemGridView h2 {
    color:  #4a4f54ff;
    /*font-size: large;*/
    font-size: 18px;
    font-weight: bold;
    /*line-height: 1rem;*/
    margin-bottom: 0.1rem;
    white-space: nowrap;
    margin-top: 0.1rem;
}

.StoreItemListView h2 {
    color:  #4a4f54ff;
    /*font-size: large;*/
    font-size: 1.125rem;
    white-space: nowrap;
    width: 17rem;
}

.StoreItemGridView p {
    color: #5b6977ff;
    font-size: 0.875rem;
    -webkit-text-size-adjust: auto;
    margin-bottom: 0.1rem;
    margin-top: 0.1rem;
}


.StoreItemListView p {
    color: #535358;
    font-size: 1rem;
}

.QuantityInfoGridView {
    display: flex;
    vertical-align: center;
    padding-left: 1rem;
}

.StoreItem .StoreItemListView .QuantityInfoListView {
    /*display: flex;*/
    /*flex-direction: row;    */
    /*align-items: center;*/
}


.AdditionalInfoGridView {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

}

.AdditionalInfoListView {
    width: 15rem;
    /*position: relative;*/
    display: flex;
    flex-direction: row;
    /*justify-content: flex-end;*/
    justify-content: space-between;
    align-items: center;
}

.LocationAndAddress {
    width: 25rem;
}

.ListViewPhoneNumber {
    width: 12rem;
}

/*.AdditionalInfo .OpenClose {*/
/*    display: flex;*/
/*    justify-content: flex-start;*/
/*}*/

/*.AdditionalInfo .Stanje {*/
/*    display: flex;*/
/*    justify-content: flex-end;*/
/*}*/


.StoreItem .OpenHoursAccess,
.StoreItem .OnStockAccess{
    color: #4a4f54ff;
    /*margin: auto;*/
    /*font-size: 0.875rem;*/
    /*margin-top: 2px;*/
    /*margin: 8px 0;*/
    display: flex;
    align-items: center;
}

.StoreItem .OpenHoursDenied,
.StoreItem .OnStockDenied{
    color: #E53132;
    /*margin: auto;*/
    /*font-size: 0.875rem;*/
    /*margin-top: 2px;*/
    display: flex;
    align-items: center;
}

.StoreItem .One {
    /*display: none;*/
    color: #F58300;
    margin: auto;
    font-size: 12px;
}

.StoreItem .Few {
    /*display: none;*/
    color: #FFAE00;
    margin: auto;
    font-size: 12px;
}

.StoreItem .Many {
    /*display: none;*/
    color: #00A4B4;
    margin: auto;
    font-size: 12px;
}

/*.StoreItemGridView.Selected .One,*/
/*.StoreItemGridView.Selected .Few,*/
/*.StoreItemGridView.Selected .Many {*/
/*    color: white;*/
/*}*/


/*.AdditionalInfo .VerticalLine {*/
/*    color: #008D36;*/
/*    margin-left: 0.313rem;*/
/*    margin-right: 0.313rem;*/
/*    font-weight: bold;*/
/*}*/

.StoreItemListView i,
.StoreItemGridView i{
    line-height: 0.4;
}

.SqBox1,
.SqBox3, .SqBox4 {
    position: relative;
}

.TooltipText {
    position: absolute;
    visibility: hidden;
    left: -10.5rem;
    top: 2.2rem;
}

.TooltipText::after {
    left:79%;
    bottom: 94%;
}

.SqBox1:hover .TooltipText,
.SqBox1:hover .TooltipText:after,
.SqBox3:hover .TooltipText,
.SqBox3:hover .TooltipText:after,
.SqBox4:hover .TooltipText,
.SqBox4:hover .TooltipText:after
{
    visibility: visible;
    opacity: 1;
    transition: 600ms ease-out;
}

.StoreItemGridView:hover .One,
.StoreItemGridView:hover .Few,
.StoreItemGridView:hover .Many
{
    display: block;
}

/*.StoreItemGridView:hover  .OnStockAccess {*/
/*    display: none;*/
/*}*/


/*.StoreItemGridView.Selected .CncIcon {*/
/*    fill: #416CAA;*/
/*}*/
.BoxHeader {
    height: 2.5rem;
    width: 100%;
    border-bottom: 1px solid #e2e8ecff;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.WorkingTime {
    width: 50%;
    display: flex;
    padding-left: 1rem;
}

.BoxHeaderBadges {
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-right: 0.375rem;
}

.BoxMiddle {
    height: 150px;
    width: 92%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.BoxFooter {
    height: 3.5rem;
    width: 100%;
    border-top: 1px solid #e2e8ecff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 0.5rem;
}

.RateBadgeText {
    color: white;
    font-weight: 800;
    font-size: 14px;
}

@media screen and (min-width: 700px){
    .StoreItemGridView {
        width: 18.875rem;
    }

    .QuantityInfoGridView {
        /*width: 60%;*/
        padding-left: 8px;
    }

    .RateBadgeText {
        font-size: 16px;
    }

    /*.BoxFooter {*/
    /*    height: 2.5rem;*/
    /*    padding: 0;*/
    /*}*/
}

.CnCBadge {
    padding-right: .375rem;
}

.PharmacyName {
    padding-left: 1rem;
    margin-top: .5rem;
    cursor: pointer;
    display: flex;
    align-items: flex-start;
}

.PharmacyContactInfo {
    margin-bottom: 1.25rem;
    padding-left: 1rem;
}

