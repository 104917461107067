.LegalDocuments {
    padding: 50px 50px;
    display: flex;
    flex-direction: column;
    justify-content: left;
}

.LegalDocuments table {
    border-collapse: collapse;
    width: max-content;
    max-width: 100%;
    overflow: auto;
}

.LegalDocuments tr {
    border-top: 1px solid #ccc;
}

.LegalDocuments th {
    padding-top: 16px;
    padding-bottom: 16px;
}

.LegalDocuments .Headers {
    font-weight: bold;
}

.LegalDocuments .TableWrapper {
    overflow: auto;
    max-width: 100%;
}

@media screen and (max-width: 700px) {
    .LegalDocuments {
        padding: 20px;

        ol,
        ul {
            padding-inline-start: 20px;
        }

        li {
            text-wrap-style: pretty;
        }

        a:link {
            overflow-wrap: break-word;
        }
    }
}