* {
    box-sizing: border-box;
}

.pageWrapFull {
    width: 100%;
    background: radial-gradient(93.97% 83.47% at 27.43% 63.56%, #04F5F4 0%, #00B2B1 100%);
}

.pageWrap {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: auto;
    background: url("../../assets/snowflake.png");
    background-position: top right;
    background-repeat: no-repeat;
    max-width: 1440px;
}

.description {
    width: 45%;
    color: #FFF;
    font-family: sans-serif;
    font-size: 18px;
    line-height: 120%;
    font-style: normal;
    font-weight: 500;
    padding: 0px 40px 30px;
}

.paragraph {
    margin-bottom: 15px;
}


.rfzoImage {
    display: block;
    margin: auto;
    width: 250px;
}

.image {
    aspect-ratio: 1;
    width: 55%;
    display: block;
}


.imageWrap {
    margin-right: 10px;
}

.storeWrap {
    margin: 30px 0;
}


@media screen and (max-width: 1200px) {
    .pageWrap {
        /* flex-direction: column; */
    }

    .description {
        /* width: 100%; */
    }

    .image {
        /* width: 100%; */
    }

    .imageWrap img {
        width: 140px;
    }
}

@media screen and (max-width: 992px) {
    .pageWrap {
        flex-direction: column;
    }

    .description {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin: 20px 0;
        align-items: flex-start;
    }

    .image {
        width: 60%;
    }

    .paragraphWrap {
        width: 45%;
    }

    .storeWrapAndContact {
        width: 45%;
    }

    .rfzoImage {
        width: 200px;
        margin: 0;
    }

    .storeWrap {
        margin: 0;
    }
    
    .imageWrap {
        display: block;
    }

    .imageWrap img {
        width: 140px;
        margin: 10px 0;
    }
}

@media screen and (max-width: 768px) {
    .pageWrap {
        flex-direction: column;
    }

    .description {
        width: 100%;
    }

    .image {
        width: 100%;
    }

    .imageWrap img {
        width: 140px;
    }
}

@media screen and (max-width: 576px) {
    .pageWrap {
        flex-direction: column;
    }

    .description {
        width: 100%;
        padding: 15px;
        flex-direction: column;
    }

    .image {
        width: 100%;
    }

    .paragraphWrap {
        width: 100%;
    }

    .storeWrapAndContact {
        width: 100%;
    }

    .imageWrap {
        display: inline-block;
    }

    .imageWrap img {
        width: 140px;
    }
}