.ArticleInfoPictureHolder {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border: 6px solid #ffffff;
    border-radius: 6px 0 0 6px;
    padding: 12px 0 0;
    box-sizing: border-box;
    width: 65%;
    height: 100%;
    position: relative;
}

.ArticleImageWrapper {
    width:100%;
    height:80%;
    position: relative;
}

.ArticleImage {
    object-fit: contain;
    width:inherit;
    height:inherit;
}

.BackWrapper {
    display: none;
}

/*treba da ostane cak i ako je prazno jer ovako gazi predefinisanu klasu u samoj ItemAvailability komponenti*/
.ItemAvailability {

}

.BadgesWrapper {
    display: flex;
    justify-content: space-between;
    position: absolute;
    align-items: center;
    z-index: 10;
    top: 0;
    width: 100%;
    box-sizing: border-box;
}

@media screen and (min-width: 700px) {
    .ArticleInfoPictureHolder {
        padding: 1rem;
        width: 36%;
    }

    .BackWrapper {
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        margin:12px;
        display: flex;
        align-items: center;
        font-size: 20px;
    }

    .BadgesWrapper {
        padding: 1rem;
    }
}