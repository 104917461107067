.InformationBoxRightWrapper {
    background-color: white;
    border-radius: 6px;
    margin-bottom: 27px;
    box-sizing: border-box;
    padding: 27px;

    border: 1px solid #E2E8EC;
    box-shadow: 0 0 0.8rem #E2E8EC;
}

.PharmacyName {
    color: #434E59;
    margin-bottom: 27px;
}

.PharmacyDescription {
    color: #434E59;
    box-sizing: border-box;
}