.ShopPage {

}

/*.DeleteIcon {*/
/*    fill: #E63133;*/
/*    height: 26px;*/
/*    width: 27px;*/
/*    !*fill: white;*!*/
/*}*/

/*.DeleteIcon:hover {*/
/*    fill: #ce0305;*/
/*}*/

.CartHeader {
    padding: 16px 24px;
    border-bottom: 1px solid #e2e8ecff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 75px;
    box-sizing: border-box;
    background-color: #00B2B1;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    color: white;
}

.CartHeader span {
    font-size: 26px;
}

.IconStyle {
    height: 24px;
    width: 24px;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    stroke: white;
}

.CartActionsHolder {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 24px;
    height: 94px;
    border-top: 1px solid #E2E8EC;
}

.ClearCart {
    cursor: pointer;
    color: #219ebcff;
    stroke: #219ebcff;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    border: none;
    background: none;
}

.ClearCartDisabled {
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    color: #888888;
    stroke: #888888;
    border: none;
    background: none;
}

.Breadcrumbs {
    display: flex;
    color: #00B1B0;
    font-weight: bold;
}

.Breadcrumbs .BreadcrumbText {
    cursor: pointer;
}