.Tooltip {
    visibility: hidden;
    display: flex;
    justify-content: center;
    align-self: center;
    /*width: content-box;*/
    font-size: 0.875rem;
    font-weight: bold;
    background-color: rgb(74,79,84);
    color: white;
    border-radius: 5px 5px 5px 5px;
    padding: 14px 14px;
    box-shadow: 0 0 0.8rem #cccccc;
    box-sizing: border-box;
    width: 14rem;
    z-index: 12;

    /* Position the tooltip */
    position: absolute;
    /*left: 3rem;*/
    /*right: 20%;*/
    /*top: 2.7rem;*/
    /*margin-left: -60px;*/
    align-items: center;
}

.Tooltip::after {
    content: "";
    position:absolute;
    z-index: 12;

    /* position tooltip correctly */
    /*left:10%;*/
    margin-left:-1px;

    /* vertically center */
    /*bottom: 94%;*/

    /* the arrow */
    border:10px solid;
    border-color: transparent transparent rgb(74,79,84) transparent;

    /*display:none;*/
}
