.InfoBox {
    position: relative;
    backface-visibility: hidden;
    /*box-shadow: 0 0 0.8rem #cccccc;*/
    border-radius: 6px;
    background-color: white;
    box-sizing: border-box;
}

@media screen and (min-width: 700px){
    .InfoBox {
        width: 39.875rem;
        height: 15.75rem;
        margin-top: 32px;
    }

    .InfoBox:hover {
        transition: 400ms ease-out;
        box-shadow: 0 0 0.75rem #a1a0a0;
        /*border: 1px solid #a1a0a0;*/
        cursor: pointer;
    }
}