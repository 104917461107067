.ErrorPage {
    height: 62vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 1rem;
}

.ErrorPage .Title {
    font-size: 22px;
    font-weight: bold;
}

.LinkToMain {
    padding: 0.5rem;
    cursor: pointer;
    text-decoration: none;
    color: #535358;
    font-size: 14px;
}

.LinkToMain:hover {
    background-color: rgba(255, 255, 255, 0.2);
}


