.CarouselContainer {
    margin: 16px auto;
    width: 1302px;
    display: flex;
    border-left: 1px solid #ebebeb;
    border-right: 1px solid #ebebeb;
}

.Slider {
    width: 100%;
}

.CarouselContainer .ButtonLeft, .CarouselContainer .ButtonRight {
    background-color: transparent ;
    border: none;
}


.CarouselContainer .ButtonLeft:hover, .CarouselContainer .ButtonRight:hover {
    color: #888888;
    font-weight: bold;
    background-color: #ebebeb;
    transition: all 200ms ease-in-out;
}

.CarouselContainer .ButtonLeft:active, .CarouselContainer .ButtonRight:active {
    color: #888888;
    font-weight: bold;
    background-color: #eeee;
    transition: all 200ms ease-in-out;

}

.CarouselContainer .slide {
    display: flex;
    justify-content: space-between;
}

.CarouselContainer  .Arrows {
    color: #d0d0d0;
    width: 24px;
    height: 24px;
}

.CarouselContainer  .Arrows:hover {
    color: #888888;
    font-weight: bold;
    transition: all 200ms ease-in-out;
}

.CarouselContainer .Arrows:active {
    color: #416CAA;
    font-size: 18px;
    font-weight: bold;
    transition: all 200ms ease-in-out;
}

