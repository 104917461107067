.ArticleInfoPageContainer {
    display: flex;
    justify-content: center;
    width: 90vw;
    margin: auto;
}
@media screen and (min-width: 700px){
    .ArticleInfoPageContainer {
        width: 100%;
    }
}

.ArticleInfoPageContentWrapper {
    margin-top: 7rem;
    /*width: 75rem;*/
    width: 1366px;
}

.PictureAndGeneralInfoBox  {
    /*height: 100%;*/
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    box-shadow: 0 0 0.8rem #cccccc;
    /*width: 100%;*/
    margin: 32px 32px 32px 32px;
    width: auto;
    height: 420px;
    /*border: 6px solid #ffffff;*/
    background-color: white;
    position: relative;
}

.PictureAndGeneralInfoBox .ArticleInfoPageArticleTextBox {
    width: 50%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    flex-flow: wrap;
    align-content: space-between;
    background-color: white;
}

.ArticleCategoryBreadcrumbs {
    font-size: small;
    margin-top: 16px;
}

.TabHolder {
    background-color: #fff;
    height: 4.375rem;
    position: relative;
    justify-content: flex-start;
    border-radius: 6px;
    box-shadow: 0 8px 0.8rem -12px #ccc;
    margin: 0 32px;
    display: flex;
    flex-direction: row;
    align-items: center;

    color: #4A4F54;
    padding: 0 1rem;
}

.TabList {
    border: none !important;
}

.ActiveTab {
    color:#00B2B1;
    border: none !important;
    box-shadow: none;
}

.ActiveTab:focus {
    box-shadow: none !important;
}

.BuyingActions {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: max-content;
}

@media screen and (min-width: 700px) {
    .BuyingActions {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: max-content;
        border-top: 1px solid #E2EBEC;
        padding-top: 1rem;
    }
    .ArticleCategoryBreadcrumbs {
        margin-left: 32px;
        font-size: initial;
    }
}

.ArticlePriceInfo {
    height: 70px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    width: 100%;
}

.Amount {
    color: rgb(1, 79, 134);
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: end;
}

.QuantityInCart {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -11px;
    right: -11px;
    border-radius: 50%;
    background-color: #103A5E;
    color: white;
    width: 24px;
    height: 24px;
}

.CheckAvailability {
    display: flex;
    justify-content: flex-end;
    padding: 1rem 1rem 0 0;
}

.CheckAvailability p {
    color: #219EBC;
    cursor: pointer;
    text-decoration: underline;
}

.ArticleInfoPageMobileWrapper {
    width: 90vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: .5rem;
}
.OriginalPrice {
    display: flex;
    color: #88959E;
    font-size: 16px;
    text-decoration: line-through;
    justify-content: center;
}

.ButtonWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.SqBox {
    position: relative;
}

.TooltipText {
    position: absolute;
    visibility: hidden;
    right: -20px;
    top: 38px;
}

.TooltipText::after {
    right:10%;
    bottom: 98%;
}

.SqBox:hover .TooltipText,
.SqBox:hover .TooltipText:after
{
    visibility: visible;
    opacity: 1;
    transition: 600ms ease-out;
}