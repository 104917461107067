.LiveChatButtonContainer {
    position: fixed;
    display: flex;
    justify-content: space-between;
    bottom: 40px;
    right: 40px;
    overflow: hidden;
    z-index: 23;
}

.LiveChatButton {
    width: 80px;
    height: 80px;
    border-radius: 20px;
    background-color: #00B2B1;
    position: fixed;
    bottom: 40px;
    right: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 23;
    cursor: pointer;
}

@media screen and (max-width: 699px){
    .LiveChatButton {
        display: none;
    }
    .TextStripe {
        display: none !important;
    }
}

.TextStripe {
    order:-1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: #fbfbfb;
    white-space:nowrap; /*Keep text always one line*/
    overflow:hidden;
    width:0;
    height:80px;
    bottom: 40px;
    margin-right: 40px;
    padding-left: 22px;
    border-radius: 20px;
    transition: width 1s;
}

.LiveChatButton:hover + .TextStripe {
    width: 386px;
}